export interface TranscodeOptions {
    input?: {
        crop: {
            x: number | string;
            y: number | string;
            width: number | string;
            height: number | string;
        };
    };
    outputs: {
        width: number | string;
        height: number | string;
        previewFrameOffsetMs: number;
    };
}

export const generateTranscodeConfig = (transcodeOptions: TranscodeOptions) => {
    const config: any = {
        outputs: {
            dash: {
                type: "dash",
                audioNormalisation: false,
                dashStreamConfigs: [
                    {
                        maxBitrate: 2500000,
                    },
                    {
                        maxBitrate: 1100000,
                    },
                    {
                        maxBitrate: 600000,
                    },
                ],
            },
            hls: {
                type: "hls",
                audioNormalisation: false,
                hlsStreamConfigs: [
                    {
                        maxBitrate: 2500000,
                    },
                    {
                        maxBitrate: 1100000,
                    },
                    {
                        maxBitrate: 600000,
                    },
                ],
            },
            mpeg4: {
                type: "mpeg4",
                audioNormalisation: true,
                maxBitrate: 2500000,
                width: transcodeOptions.outputs.width,
                height: transcodeOptions.outputs.height,
            },
            preview: {
                type: "jpg",
                previewFrameOffsetMs: transcodeOptions.outputs.previewFrameOffsetMs,
            },
        },
    };
    const inp = transcodeOptions.input?.crop;
    if (inp && (inp.height || inp.width || inp.x || inp.y)) {
        config["input"] = {
            crop: {
                x: inp.x,
                y: inp.y,
                width: inp.width,
                height: inp.height,
            },
        };
    }
    return config;
};
