import { Rnd } from "react-rnd";
import "../../css/cropper.css";
export const Cropper = (props: {
    handleResize?: any;
    handleResizeEnd?: any;
    handleDragEnd: any;
    handleDrag: any;
    rndConf: any;
    aspectRatio: any;
    coords: any;
    dims: any;
    hidden: boolean;
    name: string;
    id: number;
    overlay?: boolean;
}) => {
    return (
        <Rnd
            className={`cropper ${props.hidden && "hidden"} ${props.overlay && "overlay"}`}
            bounds="parent"
            onResize={props.handleResize}
            onResizeStop={props.handleResizeEnd}
            onDrag={props.handleDrag}
            onDragStop={props.handleDragEnd}
            default={props.rndConf}
            lockAspectRatio={props.aspectRatio}
            position={props.coords}
            size={{ width: props.dims.width, height: props.dims.height }}
            id={`cropper-${props.id}`}
            resizeHandleClasses={{
                bottomLeft: "resize-handler bl",
                bottomRight: "resize-handler br",
                topLeft: "resize-handler tl",
                topRight: "resize-handler tr",
            }}>
            <div className={`cropper-grid ${props.overlay && "overlay"}`}>
                {!props.overlay ? (
                    <>
                        <div className="top-left">
                            <span className="name">{props.name}</span>
                        </div>
                        <div className="top-mid"></div>
                        <div className="top-right"></div>

                        <div className="mid-left"></div>
                        <div className="mid-mid"></div>
                        <div className="mid-right"></div>

                        <div className="bottom-left"></div>
                        <div className="bottom-mid"></div>
                        <div className="bottom-right"></div>
                    </>
                ) : (
                    <>
                        <div className="safe-landscape"></div>
                        <div className="safe-portrait"></div>
                    </>
                )}
            </div>
        </Rnd>
    );
};
