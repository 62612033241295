import { ChangeEvent, useContext, useEffect, useState } from "react";
import { adnAxiosPost, adnFetch } from "../../functions/adnuntius/fetch";
import { v4 as uuidv4 } from "uuid";
import { CropParams, CroppedVideo } from "../videoEditor/CropWindow";
import { VideoStreamingOptions, adnPostCreative } from "../../functions/adnuntius/creative";
import { Popup } from "./Popup";
import { Files, GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { tags } from "../../functions/tags";
import { BiHelpCircle } from "react-icons/bi";
import { Tooltip } from "react-tooltip";
import { adnPostCreativeSet } from "../../functions/adnuntius/creativeSet";
import { studioParser } from "../../functions/studioParser";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { useUser } from "../../contexts/useUser";
import { generateTranscodeConfig } from "./transcodeConfig";
import { MdVideoChat } from "react-icons/md";

interface Validation {
    name: boolean;
    clickUrl: boolean;
    impTracking: boolean;
}
export const Summary = (props: {
    name: string;
    setName: any;
    advertiser: any;
    setAdvertiser: any;
    creativeSet: any;
    setCreativeSet: any;
    open: any;
    setCropParams: any;
    cropParams: CropParams;
    file: any;
    files: any;
    folio: any;
    cropArray: CroppedVideo[];
    setCropArray: any;
}) => {
    const user = useContext(useUser);
    const [url, setUrl] = useState<string>("https://");
    const [impUrl, setImpUrl] = useState<string>("https://");
    const [waiting, setWaiting] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<any>(<p>Uploading campaign</p>);
    const [tagOptions, setTagOptions] = useState<{ value: string; label: string; tag: string }[] | []>(
        tags("demo", "demo", user.teams[0].id)
    );
    const [selectedTagType, setSelectedTagType] = useState<any>(null);
    const [sizeOptions, setSizeOptions] = useState([
        { value: false, label: "No" },
        { value: true, label: "Yes" },
    ]);
    const [isDoubleSize, setDoubleSize] = useState<any>(false);
    const [loopOptions, setLoopOptions] = useState([
        { value: "loop", label: "Infinite" },
        { value: "loop3x", label: "Loop 3x" },
        { value: "stop", label: "Stop" },
    ]);
    const [fullscreenOptions, setFullscreenOptions] = useState([
        { value: "false", label: "No" },
        { value: "true", label: "Yes" },
    ]);
    const [selectedLoop, setSelectedLoop] = useState<any>(null);
    const [selectedFullscreen, setSelectedFullscreen] = useState<any>(null);
    const [validation, setValidation] = useState<Validation>({ name: false, clickUrl: false, impTracking: false });
    const [isValid, setIsValid] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(1);
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const navigate = useNavigate();

    const menuStyle = { menu: (style: any) => ({ ...style, zIndex: 100, color: "#00000088" }) };
    const itemState = {
        option: (state: any) => {
            let c = "tag-item";
            c = state.isFocused ? "tag-item-focused" : c;
            c = state.isSelected ? "tag-item-selected" : c;
            return c;
        },
    };

    const handleUploadProgress = (e: any) => {
        console.log("uploadprogress", e);
        const progress = e.progress * 100;
        setUploadProgress(progress);
    };

    const handleSelectTagType = (e: any) => {
        setSelectedTagType(e);
        localStorage.setItem("tagType", e.value);
    };
    const handleSelectSize = (e: any) => {
        setDoubleSize(e);
    };
    const handleSelectLoop = (e: any) => {
        setSelectedLoop(e);
    };
    const handleSelectFullscreen = (e: any) => {
        setSelectedFullscreen(e);
        console.log(selectedFullscreen);
    };

    const validate = () => {
        const newValidationObj: Validation = {
            name: false,
            clickUrl: false,
            impTracking: false,
        };
        if (props.name !== "") {
            newValidationObj.name = true;
        }
        // Single file
        if (props.files.length === 0) {
            if (url !== "" && url !== "https://") {
                newValidationObj.clickUrl = true;
            }
        } else if (props.files.length > 0) {
            const isInvalid = props.files.some((file: Files) => {
                return file.click === "" || file.click === "https://";
            });
            newValidationObj.clickUrl = !isInvalid;
        }
        if (!impUrl.includes("http://") && (impUrl === "" || impUrl.includes("https://"))) {
            newValidationObj.impTracking = true;
        }
        setValidation(newValidationObj);
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.setName(e.target.value);
    };
    const handleURLChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value);
    };
    const handleImpURLChange = (e: ChangeEvent<HTMLInputElement>) => {
        setImpUrl(e.target.value);
    };

    const createAsset = async (
        creativeId: any,
        dimensions: {
            x: number;
            y: number;
            width: number;
            height: number;
            outWidth: number;
            outHeight: number;
            videoWidth?: number;
            videoHeight?: number;
        },
        file: File,
        fallbackFrame?: number
    ) => {
        const url = `/v1/assets/${creativeId}/`;
        const formData = new FormData();
        const cp = props.cropParams;
        const crop = generateTranscodeConfig({
            input: { crop: { x: dimensions.x, y: dimensions.y, width: dimensions.width, height: dimensions.height } },
            outputs: {
                width: cp?.outputWidth || dimensions.videoWidth || 0,
                height: cp?.outputHeight || dimensions.videoHeight || 0,
                previewFrameOffsetMs: cp?.fallbackFrame || 0,
            },
        });
        // const _crop = {
        //     input: { crop: { x: dimensions.x, y: dimensions.y, width: dimensions.width, height: dimensions.height } },
        //     outputs: {
        //         mpeg4: {
        //             type: "mpeg4",
        //             audioNormalisation: true,
        //             maxBitrate: 2500000,
        //             width: cp.outputWidth,
        //             height: cp.outputHeight,
        //         },
        //     },
        // };
        formData.append("file", file);
        if (dimensions.outWidth !== 0 && dimensions.outHeight !== 0) {
            formData.append("transcodeConfig", JSON.stringify(crop));
            formData.append("force", "true");
        } else {
            // if is bulk uploaded - uncropped - file
            crop.input.crop = {
                width: dimensions.videoWidth || dimensions.width,
                height: dimensions.videoHeight || dimensions.height,
            };
        }
        if (fallbackFrame) {
            crop.outputs.preview.previewFrameOffsetMs = parseInt((fallbackFrame * 1000).toFixed(0));
        }
        formData.append("transcodeConfig", JSON.stringify(crop));

        console.log(crop);

        const r = await adnAxiosPost(
            `${url}${uuidv4()}`,
            {
                options: { method: "POST", body: formData },
                token: globalState.get.token(),
            },
            handleUploadProgress
        );
        return r;
    };

    const sendFile = async (creativeId: any, croppedVideo?: CroppedVideo) => {
        const url = `/v1/assets/${creativeId}/`;
        const formData = new FormData();
        if (props.cropArray.length === 0) {
            const cp = props.cropParams;
            const crop = generateTranscodeConfig({
                input: { crop: { x: cp.x, y: cp.y, width: cp.width, height: cp.height } },
                outputs: {
                    width: cp.outputWidth,
                    height: cp.outputHeight,
                    previewFrameOffsetMs: parseInt((cp.fallbackFrame * 1000).toFixed(0)),
                },
            });
            // const _crop = {
            //     input: { crop: { x: cp.x, y: cp.y, width: cp.width, height: cp.height } },
            //     outputs: {
            //         mpeg4: {
            //             type: "mpeg4",
            //             audioNormalisation: true,
            //             maxBitrate: 2500000,
            //             width: cp.outputWidth,
            //             height: cp.outputHeight,
            //         },
            //         preview: {
            //             type: "jpg",
            //             previewFrameOffsetMs: parseInt((cp.fallbackFrame * 1000).toFixed(0)),
            //         },
            //     },
            // };
            formData.append("file", props.file);
            if (cp.outputHeight !== "0" && cp.outputWidth !== "0") {
                formData.append("transcodeConfig", JSON.stringify(crop));
                formData.append("force", "true");
            }
            if (cp.fallbackFrame !== 0) {
                const simpleConfig = generateTranscodeConfig({
                    outputs: {
                        width: cp.width,
                        height: cp.height,
                        previewFrameOffsetMs: parseInt((cp.fallbackFrame * 1000).toFixed(0)),
                    },
                });
                // const cropConfig = {
                //     outputs: {
                //         mpeg4: {
                //             type: "mpeg4",
                //             audioNormalisation: true,
                //             maxBitrate: 2500000,
                //             width: cp.width,
                //             height: cp.height,
                //         },
                //         preview: {
                //             type: "jpg",
                //             previewFrameOffsetMs: parseInt((cp.fallbackFrame * 1000).toFixed(0)),
                //         },
                //     },
                // };
                formData.append("transcodeConfig", JSON.stringify(simpleConfig));
                formData.append("force", "true");
            }
            console.log(formData);
        } else if (props.cropArray.length !== 0 && croppedVideo) {
            // We have lots'a croppin' to do!
            const cp = croppedVideo;
            const crop = generateTranscodeConfig({
                input: {
                    crop: {
                        x: 2 * Math.round(cp.coords.x / 2),
                        y: 2 * Math.round(cp.coords.y / 2),
                        width: 2 * Math.round(cp.dims.width / 2),
                        height: 2 * Math.round(cp.dims.height / 2),
                    },
                },
                outputs: {
                    width: cp.outputDims.width,
                    height: cp.outputDims.height,
                    previewFrameOffsetMs: parseInt((cp.fallbackImage * 1000).toFixed(0)),
                },
            });
            // const _crop = {
            //     input: {
            //         crop: {
            //             x: 2 * Math.round(cp.coords.x / 2),
            //             y: 2 * Math.round(cp.coords.y / 2),
            //             width: 2 * Math.round(cp.dims.width / 2),
            //             height: 2 * Math.round(cp.dims.height / 2),
            //         },
            //     },
            //     outputs: {
            //         mpeg4: {
            //             type: "mpeg4",
            //             audioNormalisation: true,
            //             maxBitrate: 2500000,
            //             width: cp.outputDims.width,
            //             height: cp.outputDims.height,
            //         },
            //         dash: {
            //             type: "dash",
            //             audioNormalisation: true,
            //             dashStreamConfigs: [
            //                 {
            //                     width: cp.outputDims.width,
            //                     height: cp.outputDims.height,
            //                     maxBitrate: 2500000,
            //                 },
            //                 {
            //                     width: cp.outputDims.width,
            //                     height: cp.outputDims.height,
            //                     maxBitrate: 1100000,
            //                 },
            //                 {
            //                     width: cp.outputDims.width,
            //                     height: cp.outputDims.height,
            //                     maxBitrate: 600000,
            //                 },
            //             ],
            //         },
            //         hls: {
            //             type: "hls",
            //             audioNormalisation: true,
            //             hlsStreamConfigs: [
            //                 {
            //                     width: cp.outputDims.width,
            //                     height: cp.outputDims.height,
            //                     maxBitrate: 2500000,
            //                 },
            //                 {
            //                     width: cp.outputDims.width,
            //                     height: cp.outputDims.height,
            //                     maxBitrate: 1100000,
            //                 },
            //                 {
            //                     width: cp.outputDims.width,
            //                     height: cp.outputDims.height,
            //                     maxBitrate: 600000,
            //                 },
            //             ],
            //         },
            //     },
            // };
            formData.append("file", props.file);
            if (cp.outputDims.height !== 0 && cp.outputDims.width !== 0) {
                formData.append("transcodeConfig", JSON.stringify(crop));
                formData.append("force", "true");
            }
            console.log(formData);
        }

        const r = await adnAxiosPost(
            `${url}${uuidv4()}`,
            {
                options: { method: "POST", body: formData },
                token: globalState.get.token(),
            },
            handleUploadProgress
        );
        return r;
    };

    const onEndTranslate: { [key: string]: "loop" | "loop3x" | "stop" } = {
        Forever: "loop",
        "3x": "loop3x",
        No: "stop",
    };

    const submitCreative = async (file: Files) => {
        let fullscreen: "true" | "false" = "false";
        if (file?.fullscreen) {
            fullscreen = file.fullscreen === "Yes" ? "true" : "false";
        } else {
            fullscreen = selectedFullscreen.value;
        }

        const options: VideoStreamingOptions = {
            layout: {
                height: file.height!,
                width: file.width!,
                mute: true,
                onEnd: onEndTranslate[file.loop!],
                url: file.click!,
                impressionTrackingUrl: file.impression,
                fullscreen: fullscreen,
            },
            creativeId: uuidv4(),
            creativeName: file.name!,
            creativeSetId: props.creativeSet.id,
            token: globalState.get.token(),
        };
        const dimensions = {
            x: 0,
            y: 0,
            width: file.width!,
            height: file.height!,
            outWidth: 0,
            outHeight: 0,
            videoWidth: file.videoWidth,
            videoHeight: file.videoHeight,
        };
        const creative = await adnPostCreative.videoStreaming(options);

        const asset = await createAsset(creative.id, dimensions, file.file, file.fallbackImage);
        options.layout["video"] = { id: asset.id };
        const updatedCreative = await adnPostCreative.videoStreaming(options);
        console.log(creative, updatedCreative, asset);
    };

    const submitMultiple = async (type: "files" | "crops") => {
        setLoadingText(<p>Uploading videos</p>);
        const creativeUploadArr: Promise<any>[] = [];
        if (type === "files") {
            props.files.forEach((file: Files) => {
                creativeUploadArr.push(submitCreative(file));
            });
            await Promise.all(creativeUploadArr);
            setSuccess(true);
            setTimeout(() => {
                setWaiting(false);
                setSuccess(false);
                globalState.set.files([]);
                props.setCropArray([]);
                props.setCropParams([]);
                navigate("/creativeset/" + props.creativeSet.id);
            }, 1500);
        }
        if (type === "crops") {
            for (const i in props.cropArray) {
                setLoadingText(
                    <p>
                        Uploading file {parseInt(i) + 1} of {props.cropArray.length}
                    </p>
                );
                setUploadProgress(1);
                const options: VideoStreamingOptions = {
                    layout: {
                        height: props.cropArray[i].outputDims.height,
                        width: props.cropArray[i].outputDims.width!,
                        mute: true,
                        onEnd: selectedLoop.value,
                        url: url,
                        impressionTrackingUrl: props.file.impression,
                        fullscreen: props.file.fullscreen === "Yes" ? "true" : "false",
                    },
                    creativeId: uuidv4(),
                    creativeName: props.cropArray[i].name,
                    creativeSetId: props.creativeSet.id,
                    token: globalState.get.token(),
                };
                const creative = await adnPostCreative.videoStreaming(options);
                const asset = await sendFile(creative.id, props.cropArray[i]);
                const newOpts = { ...options };
                newOpts.layout.video = { id: asset.id };
                const creativeUpdate = await adnPostCreative.videoStreaming(newOpts);
                console.log(creative, asset, creativeUpdate);
            }
            setSuccess(true);
            setTimeout(() => {
                globalState.set.files([]);
                globalState.set.files([]);
                navigate("/creativeset/" + props.creativeSet.id);
                setWaiting(false);
            }, 1500);
            // props.cropArray.forEach((crop) => {
            //     const options: VideoStreamingOptions = {
            //         layout: {
            //             height: crop.outputDims.height,
            //             width: crop.outputDims.width!,
            //             mute: true,
            //             onEnd: onEndTranslate[props.file.loop!],
            //             url: props.file.click!,
            //             impressionTrackingUrl: props.file.impression,
            //         },
            //         creativeId: uuidv4(),
            //         creativeName: crop.name,
            //         creativeSetId: props.creativeSet.id,
            //         token: globalState.get.token(),
            //     };
            //     creativeUploadArr.push(adnPostCreative.videoStreaming(options));
            // });
            // const creatives = await Promise.all(creativeUploadArr);
            // console.log(creatives);
            // const assetUploadPromiseArray:Promise<any>[] = [];
            // creatives.forEach(creative=>{
            //     assetUploadPromiseArray.push(sendFile(creative.id, ))
            // })
        }
        // console.log(creatives);
    };

    const updateCampaign = async () => {
        const newTags = props.creativeSet.labels.filter((label: string) => !label.includes("studio-tag"));
        console.log(newTags);

        const tagLabel = `studio-tag:${selectedTagType.value}`;
        const c = await adnPostCreativeSet(globalState.get.token(), props.creativeSet.id, {
            name: props.creativeSet.name,
            folioId: props.creativeSet.folio.id,
            labels: [...newTags, tagLabel],
        });
        return c;
    };

    const submitHandler = async () => {
        if (props.files.length > 0 && props.cropArray.length === 0) {
            console.log(`studio-tag:${selectedTagType.value}`, props.creativeSet.labels);
            if (!props.creativeSet.labels.includes(`studio-tag:${selectedTagType.value}`)) {
                await updateCampaign();
            }
            setWaiting(true);
            submitMultiple("files");
            return;
        }
        // If there has been cropping
        if (props.cropArray.length !== 0) {
            setWaiting(true);
            submitMultiple("crops");
            return;
        }
        setWaiting(true);
        const height =
            props.cropParams.outputHeight !== "0"
                ? parseInt(props.cropParams.outputHeight)
                : parseInt(props.cropParams.height);
        const width =
            props.cropParams.outputWidth !== "0"
                ? parseInt(props.cropParams.outputWidth)
                : parseInt(props.cropParams.width);
        const opts: VideoStreamingOptions = {
            layout: {
                height: height,
                width: width,
                mute: false,
                onEnd: selectedLoop.value,
                url: url,
                fullscreen: selectedFullscreen.value,
            },
            creativeId: uuidv4(),
            creativeName: props.name,
            creativeSetId: props.creativeSet.id,
            token: globalState.get.token(),
        };
        if (impUrl !== "" && impUrl !== "https://") {
            opts.layout.impressionTrackingUrl = impUrl;
        }
        if (isDoubleSize.value === true) {
            opts.layout.height = height / 2;
            opts.layout.width = width / 2;
        }

        const creative = await adnPostCreative.videoStreaming(opts);
        if (creative.id) {
            setLoadingText(<p>Uploading video</p>);
            const asset = await sendFile(creative.id);
            if (asset.id) {
                setSuccess(false);
                const newOpts = { ...opts };
                newOpts.layout.video = { id: asset.id };
                const creativeUpdate = await adnPostCreative.videoStreaming(newOpts);
                console.log(creativeUpdate);
                updateCampaign();
                setTimeout(() => {
                    setSuccess(true);
                    globalState.set.files([]);
                    props.setCropArray([]);
                    props.setCropParams([]);

                    navigate("/creativeset/" + props.creativeSet.id);
                    setWaiting(false);
                }, 1500);
            }
        }
        // console.log(creative);
    };

    useEffect(() => {
        if (tagOptions.length !== 0) {
            setSelectedTagType(tagOptions[0]);
        }
        if (sizeOptions.length !== 0) {
            setDoubleSize(sizeOptions[0]);
        }
        if (loopOptions.length !== 0) {
            setSelectedLoop(loopOptions[0]);
        }
        if (fullscreenOptions.length !== 0) {
            setSelectedFullscreen(fullscreenOptions[0]);
        }
    }, [tagOptions, sizeOptions, loopOptions]);

    useEffect(() => {
        if (props.creativeSet && props.creativeSet.labels) {
            const studioObject = studioParser(props.creativeSet.labels);
            if (studioObject?.tag) {
                const tag = tagOptions.filter((opt: any) => opt.value === studioObject.tag);
                setSelectedTagType(tag[0]);
            }
        }
    }, [props.creativeSet]);

    useEffect(() => {
        const validationKeys = Object.keys(validation) as Array<keyof Validation>;
        const failedValidation = validationKeys.some((key) => {
            return validation[key] === false;
        });
        setIsValid(!failedValidation);
        // console.log(validation);
    }, [validation]);

    useEffect(() => {
        validate();
    }, [props.name, url, impUrl]);

    useEffect(() => {
        const localTagType = localStorage.getItem("tagType");
        if (localTagType) {
            const selectedTag = tagOptions.filter((tag) => tag.value === localTagType)[0];
            setSelectedTagType(selectedTag);
            return;
        }
    }, []);

    return (
        <div className={`summary ${props.open ? "open" : ""}`}>
            {waiting && (
                <Popup
                    success={success}
                    loadingText={loadingText}
                    successText={<p>Campaign created</p>}
                    progress={uploadProgress}
                />
            )}
            <h2 className="clickable">Creative details</h2>
            <div className="summary-card">
                <div className="row dir-row">
                    <div className="item">
                        <label htmlFor="name">
                            Name{" "}
                            {!validation.name && (
                                <BsExclamationTriangleFill
                                    style={{ position: "relative", top: ".1rem", color: "#d83d3d" }}
                                    data-tooltip-id="tt-name-error"
                                />
                            )}
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={props.name}
                            onChange={handleNameChange}
                            tabIndex={props.open ? 0 : -1}
                            className={!validation.name ? "error" : ""}
                        />
                    </div>
                </div>
                {props.files.length === 0 && (
                    <>
                        <div className="row dir-row">
                            <div className="item">
                                <label htmlFor="clickUrl">
                                    Click url{" "}
                                    {!validation.clickUrl && (
                                        <BsExclamationTriangleFill
                                            style={{ position: "relative", top: ".1rem", color: "d83d3d" }}
                                            data-tooltip-id="tt-click-error"
                                        />
                                    )}
                                </label>
                                <input
                                    type="text"
                                    name="clickUrl"
                                    id="clickUrl"
                                    placeholder=""
                                    onChange={handleURLChange}
                                    tabIndex={props.open ? 0 : -1}
                                    value={url}
                                    className={!validation.clickUrl ? "error" : ""}
                                />
                            </div>
                        </div>
                        <div className="row dir-row">
                            <div className="item">
                                <label htmlFor="impUrl">
                                    Impression tracking url{" "}
                                    {!validation.impTracking && (
                                        <BsExclamationTriangleFill
                                            style={{ position: "relative", top: ".1rem", color: "d83d3d" }}
                                            data-tooltip-id="tt-imp-error"
                                        />
                                    )}
                                </label>
                                <input
                                    type="text"
                                    name="impUrl"
                                    id="impUrl"
                                    placeholder=""
                                    onChange={handleImpURLChange}
                                    tabIndex={props.open ? 0 : -1}
                                    value={impUrl}
                                    className={!validation.impTracking ? "error" : ""}
                                />
                            </div>
                        </div>
                        <div className="row dir-row">
                            <div className="item">
                                <label htmlFor="tag-select">Default tag type</label>
                                <Select
                                    name="tag-select"
                                    id="tag-select"
                                    options={tagOptions}
                                    value={selectedTagType}
                                    onChange={handleSelectTagType}
                                    styles={menuStyle}
                                    menuPlacement="top"
                                    classNames={itemState}
                                    className="select-tag"
                                />
                            </div>
                            <div className="item">
                                <label htmlFor="size-select">Double resolution</label>
                                <Select
                                    name="size-select"
                                    id="size-select"
                                    options={sizeOptions}
                                    value={isDoubleSize}
                                    onChange={handleSelectSize}
                                    styles={menuStyle}
                                    menuPlacement="top"
                                    classNames={itemState}
                                    className="select-tag"
                                />
                            </div>
                        </div>
                        <div className="row dir-row">
                            <div className="item">
                                <label htmlFor="loop-select">Loop</label>
                                <Select
                                    name="loop-select"
                                    id="loop-select"
                                    options={loopOptions}
                                    value={selectedLoop}
                                    onChange={handleSelectLoop}
                                    styles={menuStyle}
                                    classNames={itemState}
                                    className="select-tag"
                                    menuPlacement="top"
                                    menuPosition="absolute"
                                />
                            </div>
                            <div className="item">
                                <label htmlFor="fullscreen-select">Fullscreen</label>
                                <Select
                                    name="fullscreen-select"
                                    id="fullscreen-select"
                                    options={fullscreenOptions}
                                    value={selectedFullscreen}
                                    onChange={handleSelectFullscreen}
                                    styles={menuStyle}
                                    classNames={itemState}
                                    className="select-tag"
                                    menuPlacement="top"
                                    menuPosition="absolute"
                                />
                            </div>
                        </div>
                    </>
                )}
                {props.files.length > 0 && (
                    <>
                        <div className="row">
                            <div className="item">
                                <label htmlFor="tag-select">
                                    Default tag type <BiHelpCircle data-tooltip-id="tt-tag-help" />
                                </label>
                                <Select
                                    name="tag-select"
                                    id="tag-select"
                                    options={tagOptions}
                                    value={selectedTagType}
                                    onChange={handleSelectTagType}
                                    styles={menuStyle}
                                    menuPlacement="top"
                                    classNames={itemState}
                                    className="select-tag"
                                />
                            </div>
                        </div>
                        <Tooltip
                            id="tt-name-error"
                            content="A creative name is required"
                            place="top"
                            variant="info"
                            opacity={1}
                            noArrow
                        />
                        <Tooltip
                            id="tt-click-error"
                            content="A click URL is required and must begin with 'https://'"
                            place="top"
                            variant="info"
                            opacity={1}
                            noArrow
                        />
                        <Tooltip
                            id="tt-imp-error"
                            content="If an impression tracking URL is supplied it must begin with 'https://'"
                            place="top"
                            variant="info"
                            opacity={1}
                            noArrow
                        />
                        <Tooltip
                            id="tt-tag-help"
                            content="Select which default tag type to display when exporting tags for this campaign"
                            place="top"
                            variant="info"
                            opacity={1}
                            noArrow
                        />
                        <Tooltip
                            id="tt-size-help"
                            content='Select "Yes" if you are uploading the video in double resolution'
                            place="top"
                            variant="info"
                            opacity={1}
                            noArrow
                        />
                    </>
                )}
                {/* <div className="row dir-col">
                    <span className="item">
                        <span>Advertiser</span>
                        <span>{props.advertiser.name}</span>
                    </span>
                    <span className="item">
                        <span>Campaign</span>
                        <span>{props.order.name}</span>
                    </span>
                </div> */}
            </div>
            <input
                type="button"
                className={isValid ? "" : "disabled"}
                value="Submit"
                tabIndex={props.open ? 0 : -1}
                onClick={submitHandler}
            />
        </div>
    );
};
