import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from "recharts";

export type Quartile = {
    name: string;
    events: number;
};

export const Quartiles = (props: { data: Quartile[] }) => {
    return (
        <div className="quartiles">
            <h4>Quartiles</h4>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    height={500}
                    width={500}
                    data={props.data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 20,
                        bottom: 0,
                    }}>
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey="name" axisLine={false} />
                    {/* <YAxis axisLine={false} /> */}
                    <Tooltip />
                    <Area type="monotone" dataKey="events" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};
