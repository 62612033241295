import { adnFetch } from "./fetch";

interface UserObject {
    [key: string]: any;
    username: string;
    password: string;
}

type MasqueradeUser = {
    [key: string]: any;
    username: string;
    password: string;
    masqueradeUser: string;
    twoFactorAuthCode: string;
};

export const adnAuthenticate = async (userObject: UserObject) => {
    return await adnFetch("/authenticate", {
        urlParams: {
            ...userObject,
            grant_type: "password",
            scope: "ng_api",
        },
    });
};

export const adnMasquerade = async (userObject: MasqueradeUser) => {
    return await adnFetch("/masquerade", {
        urlParams: {
            ...userObject,
            grant_type: "password",
            scope: "ng_api",
        },
    });
};

export const adn2fa = async (token: string, twoFAKey: string) => {
    const formData = new FormData();
    formData.append("twoFactorAuthCode", twoFAKey);
    formData.append("auth_token", token);
    const postBody = {
        code: parseInt(twoFAKey),
        auth_token: token,
    };
    console.log(parseInt(twoFAKey));
    return await adnFetch("/authenticate/2fa", {
        options: {
            method: "POST",
            body: JSON.stringify(postBody),
            // body: formData,
            headers: {
                "Content-Type": "application/json",
            },
        },
        urlParams: {
            auth_token: token,
        },
    });
};
