import reactStringReplace from "react-string-replace";

export const helpParser = (content: string) => {
    const map: { [key: string]: string } = {
        "creative set": "creativeset",
        folio: "folio",
    };
    let newContent: any;
    newContent = reactStringReplace(content, "creative set", (match, i) => <a href={`/help#${map[match]}`}>{match}</a>);
    newContent = reactStringReplace(newContent, "folio", (match, i) => <a href={`/help#${map[match]}`}>{match}</a>);
    newContent = reactStringReplace(newContent, "\n", (match, i) => <br />);
    return newContent;
};
