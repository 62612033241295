import { AdnItemWithId, ToolboxActions } from "./Toolbox";

export const Description = (props: {
    items: AdnItemWithId[];
    action: ToolboxActions;
    type: "folio" | "creativeSet";
}) => {
    const pluralS = props.items.length === 1 ? "" : "s";
    const pluralThis = props.items.length === 1 ? "this" : "these";
    switch (props.action) {
        case "rename":
            return (
                <p>
                    Edit the name{pluralS} of the {props.type === "folio" ? "Folio" : ""}
                    {props.type === "creativeSet" ? "Creative set" : ""}
                    {pluralS} below.
                </p>
            );
        case "delete":
            return (
                <p className="warning">
                    Delete {pluralThis} {props.type === "folio" ? "Folio" : ""}
                    {props.type === "creativeSet" ? "Creative set" : ""}
                    {pluralS}?
                </p>
            );
        case "copy":
            return (
                <p>
                    Enter the new name{pluralS} of the copied {props.type === "folio" ? "Folio" : ""}
                    {props.type === "creativeSet" ? "Creative set" : ""}
                    {pluralS}.
                </p>
            );
    }
};
