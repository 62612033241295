import { Tabulator as TabulatorTypes } from "react-tabulator/lib/types/TabulatorTypes";

export const previewFormatter: TabulatorTypes.Formatter = (cell: any, formatterParams: any, onRendered: any) => {
    onRendered(onPreviewRendered);
    const vidEl = document.createElement("video");
    vidEl.muted = true;
    vidEl.classList.add("video-preview");
    return vidEl;
};

export const onPreviewRendered = async (cell: any, files: any, addDataToRow: any) => {
    const data = cell.getData();
    const row = cell.getRow();

    const url = await URL.createObjectURL(files[data.index]);

    const el = cell.getElement();
    const videoEl = el.querySelector("video");
    videoEl.autoPlay = true;
    videoEl.src = url;

    videoEl.addEventListener("loadedmetadata", () => {
        addDataToRow(row, url, { width: videoEl.videoWidth, height: videoEl.videoHeight });
    });
};
export const onFallbackRendered = async (cell: any, files: any, addDataToRow: any) => {
    const data = cell.getData();
    const row = cell.getRow();

    const url = await URL.createObjectURL(files[data.index]);

    const el = cell.getElement();
    const videoEl = el.querySelector("video");
    videoEl.autoPlay = true;
    videoEl.src = url;
    videoEl.currentTime = data.fallbackOffset;

    videoEl.addEventListener("loadedmetadata", () => {
        addDataToRow(row, url, { width: videoEl.videoWidth, height: videoEl.videoHeight });
    });
};
