export const videoStreaming = {
    assets: [],
    name: "Annonsemateriell for Test #2",
    creativeSet: { id: "05e0499e-4cb8-4b4f-ae18-5de5e5b5d82b" },
    cToAssets: {},
    userState: "APPROVED",
    type: "INTERNAL",
    teams: [],
    dimensionType: "FIXED",
    layout: {
        id: "video_streaming",
    },
    width: 500,
    height: 400,
    // cToText: {},
    // cToUrls: { destination: "http://example.com" },
    // cToChoices: { mute: "No", onEnd: "Loop forever" },
    sponsorshipPercentage: null,
    impressionTrackingUrls: [],
    overridableLayoutParameters: [],
    overridableTargeting: [],
    overrideFields: [],
    layoutParameters: { destination: "http://example.com", mute: "No", onEnd: "Loop forever" },
    cpm: null,
};
