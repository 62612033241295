import { ChangeEvent, useContext, useEffect, useState } from "react";
import { adnAdvertisers } from "../../functions/adnuntius/advertisers";
import { useUser } from "../../contexts/useUser";
import { Item } from "./Item";
import { useNavigate } from "react-router-dom";
import { BsPersonVcard } from "react-icons/bs";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { Create } from "./Create/Create";
import { Tooltip } from "react-tooltip";
import { helpParser } from "../../functions/helpParser";

export const Advertisers = (props: { setSelectedAdvertiser: any; setFolioId: any }) => {
    const [advertisers, setAdvertisers] = useState<any[]>([]);
    // const [selectedAdvertiser, setSelectedAdvertiser] = useState<any>(undefined);
    const [filteredAdvertisers, setFilteredAdvertisers] = useState<any[]>([]);
    const [filter, setFilter] = useState<string>("");
    const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const user = useContext(useUser);
    const navigate = useNavigate();

    const toggleIsCreateVisible = () => {
        setIsCreateVisible(!isCreateVisible);
    };

    const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    };

    // const isActive = (advertiser: any) => {
    //     if (advertiser.id === selectedAdvertiser?.id) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // };

    useEffect(() => {
        const q = filter.toLowerCase();
        if (filter !== "") {
            const filtered = filteredAdvertisers.filter((adv) => adv.name.toLowerCase().includes(q));
            setFilteredAdvertisers(filtered);
        } else {
            setFilteredAdvertisers(advertisers);
        }
    }, [advertisers, filter, filteredAdvertisers]);

    useEffect(() => {
        const userTeams = () => {
            const teamNames: string[] = [];
            user.user.userRoles.rolesByNetwork.forEach((rbn: any) => {
                if (rbn.network.id !== "adnuntius") {
                    return false;
                }
                rbn.roles.forEach((role: any) => {
                    role?.team && teamNames.push(role.team.name);
                });
            });
            return teamNames;
        };
        const fetchAdvertisers = async () => {
            const advertisers = await adnAdvertisers(globalState.get.token(), userTeams());
            if (advertisers?.code === "error.unauthorised") {
                globalState.set.token("");
            }
            setAdvertisers(advertisers.results);
            setFilteredAdvertisers(advertisers.results);
        };
        if (advertisers && advertisers.length === 0) {
            fetchAdvertisers();
        }
    }, [advertisers, globalState.get, globalState.set, user.user.userRoles.rolesByNetwork]);
    return (
        <div className="advertisers">
            <div className="top">
                <div
                    className="add-advertiser button new-advertiser"
                    onClick={() => {
                        setIsCreateVisible(true);
                    }}>
                    <BsPersonVcard
                        style={{
                            float: "left",
                            marginRight: ".5rem",
                            position: "relative",
                            top: ".05rem",
                            fontSize: 16,
                        }}
                    />
                    <span>New</span>
                </div>
                <h2>
                    <div className="crumbs"></div>
                    <BsPersonVcard />
                    Advertisers
                </h2>
                <input
                    type="text"
                    name=""
                    id="advertiser-filter"
                    placeholder="Search advertiser"
                    onChange={handleFilter}
                    className="search-help"
                />
            </div>
            <div className="list-header">
                <span>Advertiser name</span>
            </div>
            <ul>
                {advertisers &&
                    advertisers.length !== 0 &&
                    filteredAdvertisers.map((advertiser, i) => {
                        return (
                            <li
                                key={i}
                                onClick={() => {
                                    props.setSelectedAdvertiser(advertiser.id);
                                    navigate(`/advertiser/${advertiser.id}`);
                                }}
                                // className={isActive(advertiser) ? "active" : ""}
                            >
                                <Item
                                    value={
                                        <>
                                            <span>
                                                <BsPersonVcard style={{ marginRight: "1rem" }} />
                                                {advertiser.name}
                                            </span>
                                        </>
                                    }
                                />
                            </li>
                        );
                    })}
            </ul>
            <Create
                type="advertiser"
                visible={isCreateVisible}
                setVisible={toggleIsCreateVisible}
                onCreateAdvertiser={() => setAdvertisers([])}
                user={user}
            />
            <Tooltip
                anchorSelect=".search-help"
                render={() => helpParser("Filters the list of advertisers based on your search term.")}
                place="bottom"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect=".new-advertiser"
                render={() => helpParser("Click here to create a new advertiser.")}
                place="left"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
        </div>
    );
};
