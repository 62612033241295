import { useContext, useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { adnCopy } from "../../../functions/adnuntius/copy";
import { useGlobalState, GlobalStateManager } from "../../../contexts/useGlobalState";
import { Checkbox } from "../../Checkbox";
import { useNavigate } from "react-router-dom";
import { CgCheckO } from "react-icons/cg";

type CopyProps = {
    folioId?: string;
    creativeSetId?: string;
    name: string;
    onCopySuccess?: any;
};
export const Copy = (props: CopyProps) => {
    const [dialog, setDialog] = useState<boolean>(false);
    const [newName, setNewName] = useState<string>(`Copy of ${props.name}`);
    const [isCopying, setIsCopying] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const navigate = useNavigate();

    const handleBackdropClick = (e: any) => {
        if (e.target.classList.contains("copy-backdrop")) setDialog(!dialog);
    };
    const handleNameChange = (e: any) => {
        setNewName(e.target.value);
    };
    const handleCheck = () => {
        setIsChecked(!isChecked);
    };

    const copy = async (e: any) => {
        e.preventDefault();
        if (!isCopying) {
            setIsCopying(true);
            let copyResponse: any;
            if (props.folioId) {
                copyResponse = await adnCopy(globalState.get.token(), "folio", props.folioId, newName);
                if (isChecked && copyResponse.id) {
                    navigate(`/folio/${copyResponse.id}`, { state: { cache: new Date().toTimeString() } });
                } else {
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 3500);
                    props.onCopySuccess && props.onCopySuccess();
                }
                setIsCopying(false);
            }
            if (props.creativeSetId) {
            }
        }
    };
    const Description = () => {
        let content = <p></p>;
        if (props.folioId) {
            content = <p>Copy the current folio and all its contents to a new folio with the name specified below.</p>;
        } else if (props.creativeSetId) {
            content = (
                <p>
                    Copy the current creative set and all its contents to a new creative set with the name specified
                    below.
                </p>
            );
        }
        return content;
    };
    useEffect(() => {
        if (dialog) {
            const el = document.getElementById("copy-name") as HTMLInputElement;
            el && el.select();
        }
    }, [dialog]);
    return (
        <>
            <div className="copy-folio button" onClick={() => setDialog(!dialog)}>
                <MdContentCopy
                    style={{
                        float: "left",
                        marginRight: 0,
                        position: "relative",
                        top: ".05rem",
                        fontSize: 16,
                    }}
                />
            </div>
            {dialog && (
                <div className="copy-backdrop" onClick={handleBackdropClick}>
                    <div className="copy-dialog">
                        {success && (
                            <div className="success">
                                <CgCheckO className="icon" />
                                <p>
                                    {props.folioId && "Folio"}
                                    {props.creativeSetId && "Creative set"} succesfully copied to <span>{newName}</span>
                                </p>
                            </div>
                        )}
                        <Description />
                        <form onSubmit={copy}>
                            <input type="text" id="copy-name" value={newName} onChange={handleNameChange} />
                            <Checkbox
                                checked={isChecked}
                                label={`Go to created ${props.folioId ? "folio" : "creative set"} after creation`}
                                onCheck={handleCheck}
                            />
                            <div className="row end">
                                <div className="button" onClick={() => setDialog(false)}>
                                    Cancel
                                </div>
                                <input
                                    className={`button ${isCopying ? "disabled" : ""}`}
                                    type="submit"
                                    value={`Copy${isCopying ? "ing" : ""}`}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};
