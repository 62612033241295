import { adnFetch } from "./fetch";

export type SearchTypes = "Advertiser" | "CreativeSet" | "Folio";

export const adnSearch = async (token: string, query: string, types?: SearchTypes[]) => {
    let qTypes: SearchTypes[] = ["Advertiser", "CreativeSet", "Folio"];
    let typesString = "";
    if (types && types.length !== 0) {
        typesString = types.toString().replaceAll(",", ";");
    } else {
        typesString = qTypes.toString().replaceAll(",", ";");
    }

    const search = await adnFetch("/v1/search", {
        urlParams: {
            q: query,
            objectState: "ACTIVE",
            pageSize: "15",
            types: typesString,
        },
        token: token,
    });
    return search;
};
