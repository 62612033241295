export const getLogo = (client: string) => {
    const logos: { [key: string]: string } = {
        default: "../Studio-logo.svg",
        remedia: "../remedia.svg",
        precis: "../precis.svg",
        try: "../try.svg",
        noa: "../noa.svg",
        fullstakk: "../fullstakk.svg",
        ipg: "../ipg-logo.png",
        kobler: "../kobler.svg",
    };
    return logos[client];
};
