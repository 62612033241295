import { MdContentCopy, MdOutlineCancel, MdOutlineCheckCircle } from "react-icons/md";
import { BiTrash } from "react-icons/bi";
import { HiOutlinePencil } from "react-icons/hi";
import { ToolboxDialog } from "./ToolboxDialog";
import { useState } from "react";
import { TbTools } from "react-icons/tb";
import { Tooltip } from "react-tooltip";

export type ToolboxActions = "copy" | "rename" | "delete";

export type AdnItemWithId = {
    [key: string]: any;
    id: string;
    name: any;
};

type ToolboxProps = {
    type: "folio" | "creativeSet";
    items?: AdnItemWithId[];
    active?: boolean;
    onActionSuccess?: any;
    isOpen: boolean;
    onOpen: any;
};

export const Toolbox = (props: ToolboxProps) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [action, setAction] = useState<ToolboxActions>("copy");
    const isActive = props.active || props?.items?.length !== 0;

    const handleButtonClick = (action: ToolboxActions) => {
        setAction(action);
        setShowDialog(true);
    };

    return (
        <>
            {!!props?.items?.length && showDialog && (
                <ToolboxDialog
                    items={props.items}
                    type={props.type}
                    action={action}
                    setVisible={() => setShowDialog(false)}
                    onActionSuccess={props.onActionSuccess}
                    setShowDialog={setShowDialog}
                />
            )}
            {!props.isOpen && (
                <div className="toolbox unopened">
                    <div className="button edit" onClick={props.onOpen}>
                        <TbTools />
                    </div>
                </div>
            )}
            {props.isOpen && (
                <div className={`toolbox ${isActive ? "" : "disabled"}`}>
                    <div
                        data-tooltip-id="tt-copy"
                        className="button copy"
                        onClick={() => {
                            handleButtonClick("copy");
                        }}>
                        <MdContentCopy />
                    </div>
                    <div
                        data-tooltip-id="tt-rename"
                        className="button rename"
                        onClick={() => {
                            handleButtonClick("rename");
                        }}>
                        <HiOutlinePencil />
                    </div>
                    <div
                        data-tooltip-id="tt-delete"
                        className="button delete"
                        onClick={() => {
                            handleButtonClick("delete");
                        }}>
                        <BiTrash />
                    </div>
                    <MdOutlineCancel className="cancel" onClick={props.onOpen} />
                </div>
            )}
            <Tooltip
                id="tt-copy"
                content="Copy"
                place="top"
                variant="info"
                className="tiny"
                opacity={1}
                delayShow={1000}
            />
            <Tooltip
                id="tt-rename"
                content="Rename"
                place="top"
                variant="info"
                className="tiny"
                opacity={1}
                delayShow={1000}
            />
            <Tooltip
                id="tt-delete"
                content="Delete"
                place="top"
                variant="info"
                className="tiny"
                opacity={1}
                delayShow={1000}
            />
        </>
    );
};
