import { renderToStaticMarkup } from "react-dom/server";
import { BiCheckboxChecked, BiCheckbox } from "react-icons/bi";
import { Tabulator as TabulatorTypes } from "react-tabulator/lib/types/TabulatorTypes";

export const doubleFormatter: TabulatorTypes.Formatter = (cell: any, formatterParams: any, onRendered: any) => {
    const data = cell.getData();
    let returnString;
    returnString =
        data.double === "Yes"
            ? renderToStaticMarkup(<BiCheckboxChecked size={22} />)
            : renderToStaticMarkup(<BiCheckbox size={22} />);
    return returnString;
};
