import { FormEvent, useEffect, useState } from "react";
import { cmApi, getAccountInfo, setAccountInfo } from "../functions/campaignManager";
import { checkGoogleAuthParams } from "../functions/campaignManager/cmApi";

function CampaignManagerAuth() {
    const [profiles, setProfiles] = useState<any[]>([]);
    const [advertisers, setAdvertisers] = useState<any[]>([]);
    const [campaigns, setCampaigns] = useState<any[]>([]);

    const [search, setSearch] = useState<string>("");
    const [selectedCampaign, setSelectedCampaign] = useState<string>("");
    const [selectedAdvertiser, setSelectedAdvertiser] = useState<string>("");

    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    if (cmApi.checkGoogleAuthParams()) {
        if (!isLoggedIn) {
            setIsLoggedIn(true);
        }
    }
    const getProfiles = async () => {
        const profileRequest = await cmApi.sendCmRequest("get", "/userprofiles");
        if (profileRequest?.items) {
            setProfiles(profileRequest.items);
            console.log(profileRequest.items);
        }
    };

    const getAdvertisers = async (e: FormEvent) => {
        e.preventDefault();
        const profile = getAccountInfo();
        const advertiserRequest = await cmApi.sendCmRequest("get", `/userprofiles/${profile.profileId}/advertisers`, {
            searchString: search,
        });
        setAdvertisers(advertiserRequest.advertisers);
    };

    const getCampaigns = async () => {
        const profile = getAccountInfo();
        const campaignRequest = await cmApi.sendCmRequest("get", `/userprofiles/${profile.profileId}/campaigns`, {
            advertiserIds: [selectedAdvertiser],
        });
        setCampaigns(campaignRequest.campaigns);
        console.log(campaignRequest.campaigns);
    };

    const setProfile = (profile: any) => {
        setAccountInfo(profile);
        setProfiles([]);
    };

    const setAdvertiser = (advertiser: any) => {
        setAdvertisers([]);
        setSelectedAdvertiser(advertiser.id);
    };

    const setCampaign = (campaign: any) => {
        setCampaigns([]);
        setSelectedCampaign(campaign.id);
    };

    const pushCreative = async () => {
        const profile = getAccountInfo();
        const creatives = [
            {
                name: "Upload test 2",
                advertiserId: selectedAdvertiser,
                accountId: profile.accountId,
                active: true,
                archived: false,
                size: {
                    width: 980,
                    height: 240,
                },
                type: 3,
                htmlCode: "<h2>Testing more upload</h2>",
            },
        ];

        for (const creative of creatives) {
            console.log(creative);
            const creativeResponse = await cmApi.sendCmRequest(
                "post",
                `/userprofiles/${profile.profileId}/creatives`,
                undefined,
                creative
            );
            console.log(creativeResponse.id);
            const linkResponse = await cmApi.sendCmRequest(
                "post",
                `/userprofiles/${profile.profileId}/campaigns/${selectedCampaign}/campaignCreativeAssociations`,
                undefined,
                { creativeId: creativeResponse.id }
            );
            console.log(linkResponse);
        }
    };

    useEffect(() => {
        const url = localStorage.getItem("beforeAuthUrl");
        if (isLoggedIn && url) {
            document.location.href = url;
        } else {
            getProfiles();
        }
    }, [isLoggedIn, profiles]);

    return (
        <div className="App">
            {!isLoggedIn && (
                // <header className="App-header">
                //     <p>Selected Advertiser: {selectedAdvertiser} </p>
                //     <p>Selected Campaign: {selectedCampaign} </p>

                //     {profiles.map((profile) => (
                //         <div key={profile.accountId} onClick={() => setProfile(profile)}>
                //             {profile.accountName}
                //         </div>
                //     ))}

                //     {advertisers.map((advertiser) => (
                //         <div key={advertiser.id} onClick={() => setAdvertiser(advertiser)}>
                //             {advertiser.name}
                //         </div>
                //     ))}

                //     {campaigns.map((campaign) => (
                //         <div key={campaign.id} onClick={() => setCampaign(campaign)}>
                //             {campaign.name}
                //         </div>
                //     ))}

                //     <button onClick={getProfiles}>Get Profiles</button>
                //     <form onSubmit={getAdvertisers}>
                //         <input onChange={(e) => setSearch(e.currentTarget.value)}></input>
                //         <button>search Advertisers</button>
                //     </form>
                //     <button onClick={getCampaigns}>Get Campaigns</button>
                //     <button onClick={pushCreative}>PUSH Creative</button>
                // </header>
                <p>Initiating Google Sign In</p>
            )}
        </div>
    );
}

export default CampaignManagerAuth;
