import { adnFetch } from "./fetch";

export const adnStats = async (
    token: string,
    opts: {
        creativeSetId: string;
    }
) => {
    const stats = await adnFetch("/v1/stats", {
        urlParams: {
            creativeSetId: opts.creativeSetId,
            groupBy: "DAILY",
            includeChunkRange: "true",
            showFilteredBidReasons: "true",
        },
        token: token,
    });
    return stats;
};
