import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Files, GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { BiTrash } from "react-icons/bi";
import { CroppedVideo } from "../videoEditor/CropWindow";
import { CgColorPicker } from "react-icons/cg";
import { Tooltip } from "react-tooltip";
import { helpParser } from "../../functions/helpParser";

export const DropZone = (props: {
    hovering: boolean;
    handleDragEnter: any;
    handleDragLeave: any;
    handleDrop: any;
    file: any;
    urlBlob: any;
    cropParams: any;
    files?: Files[];
    handleRemoveFile: any;
    cropArray: CroppedVideo[];
}) => {
    const [videoLength, setVideoLength] = useState<any>(false);
    const [showDetails, setShowDetails] = useState<any>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const navigate = useNavigate();
    let fileSize;
    const fsMb = props.file.size / 1000000;
    if (fsMb < 1) {
        fileSize = (props.file.size / 1000).toLocaleString() + " kB";
    } else {
        fileSize = fsMb.toLocaleString() + " MB";
    }
    useEffect(() => {
        setTimeout(() => {
            const vid = document.getElementById("video-preview") as any;
            if (vid?.duration) {
                if (vid.duration > 60) {
                    const min = vid.duration / 60;
                    const sec = vid.duration - min * 60;
                    setVideoLength(`${min}:${sec}`);
                } else {
                    setVideoLength(vid.duration.toFixed(1) + "s");
                }
            }
        }, 200);
    }, [props.file]);
    return (
        <div
            style={{}}
            className={`dropzone ${props.hovering ? "hovering" : ""}`}
            onClick={() => {}}
            onDragOver={props.handleDragEnter}
            onDragLeave={props.handleDragLeave}
            onDrop={props.handleDrop}>
            {props.file && !props.file.type.includes("video") && (
                <>
                    <p className="warning">✕</p>
                    <p className="warning-text">Only video files supported</p>
                </>
            )}
            {props.file && props.urlBlob && props.file.type.includes("video") && (
                <div className="dropped-file">
                    {/* <div className="pseudo-file"></div> */}
                    <video
                        id="video-preview"
                        src={props.urlBlob}
                        autoPlay
                        playsInline
                        loop
                        muted
                        onClick={() =>
                            navigate("/new", { state: { cropArray: props.cropArray, cropParams: props.cropParams } })
                        }></video>
                    <p className="filename">{props.file.name}</p>
                    {!!props.cropArray.length && (
                        <div className="details">
                            <span className="showDetails">Cropped videos</span>
                            {props.cropArray.map((crop, i) => (
                                <div className="row" key={i}>
                                    <span className="title">{crop.name}</span>
                                    <span className="value">
                                        {crop.outputDims.width} x {crop.outputDims.height}px
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                    <span className="showDetails" onClick={() => setShowDetails(!showDetails)}>
                        {showDetails ? "hide details" : "show details"}
                    </span>
                    {showDetails && (
                        <div className="details">
                            {videoLength && (
                                <div className="row">
                                    <span className="title">Video duration</span>
                                    <span className="value">{videoLength}</span>
                                </div>
                            )}
                            <div className="row">
                                <span className="title">File size</span>
                                <span className="value">{fileSize}</span>
                            </div>
                            <div className="row">
                                <span className="title">Type</span>
                                <span className="value">{props.file.type}</span>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {props.files && props.files.length > 0 && (
                <>
                    <h2>Video files</h2>

                    {props.files.map((f, i) => (
                        <div key={i} className="video-list-preview">
                            <div className="video-preview-container">
                                <video src={f.blobUrl} muted></video>
                                <div
                                    className="delete button"
                                    onClick={() => {
                                        props.handleRemoveFile(i);
                                    }}>
                                    <BiTrash />
                                </div>
                            </div>
                            <span className="title">{f.name}</span>
                        </div>
                    ))}
                    <div
                        className="button edit"
                        onClick={() => {
                            navigate("/new/bulk");
                        }}>
                        Edit files
                    </div>
                </>
            )}
            {!props.file && props.files && props.files.length === 0 && (
                <>
                    <p>Drop your file here</p>
                </>
            )}
        </div>
    );
};
