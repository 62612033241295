import { useContext, useEffect, useState } from "react";
import { Description } from "./Description";
import { AdnItemWithId, ToolboxActions } from "./Toolbox";
import { RenameList } from "./RenameList";
import {
    AdnPostCreativeSetOptions,
    adnDeleteCreativeSet,
    adnUpdateCreativeSet,
} from "../../../functions/adnuntius/creativeSet";
import { useGlobalState, GlobalStateManager } from "../../../contexts/useGlobalState";
import { CopyList } from "./CopyList";
import { adnCopy } from "../../../functions/adnuntius/copy";
import { FolioOptions, adnDeleteFolio, adnPostFolio } from "../../../functions/adnuntius/folios";
import { DeleteList } from "./DeleteList";
import { Checkbox } from "../../Checkbox";
import { OmniSearch } from "../../OmniSearch";
import { useNavigate } from "react-router-dom";
type DialogProps = {
    items: AdnItemWithId[];
    type: "folio" | "creativeSet";
    action: ToolboxActions;
    setVisible: any;
    onActionSuccess?: any;
    setShowDialog: any;
};

export const ToolboxDialog = (props: DialogProps) => {
    const [editedItems, setEditedItems] = useState<AdnItemWithId[] | null>(null);
    const [moveCheck, setMoveCheck] = useState<boolean>(false);
    const [selectedFolio, setSelectedFolio] = useState<{ label: string; type: string; value: string } | null>(null);
    const [goTo, setGoTo] = useState<boolean>(true);
    const [working, setWorking] = useState<boolean>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const navigate = useNavigate();

    const handleSearchSelect = (data: any) => {
        setSelectedFolio(data);
    };

    const rename = async () => {
        setWorking(true);
        const promiseArray: Promise<any>[] = [];
        editedItems?.forEach((item) => {
            if (props.type === "creativeSet") {
                const opts: AdnPostCreativeSetOptions = {
                    folioId: item.folio.id,
                    name: item.name,
                    labels: item.labels,
                };
                promiseArray.push(adnUpdateCreativeSet(globalState.get.token(), item.id, opts));
            } else if (props.type === "folio") {
                const opts: FolioOptions = {
                    advertiserId: item.advertiser.id,
                    name: item.name,
                    teamId: item.team.id,
                };
                promiseArray.push(adnPostFolio(globalState.get.token(), item.id, opts));
            }
        });
        const results = await Promise.all(promiseArray);
        props.setShowDialog(false);
        props.onActionSuccess && props.onActionSuccess();
        setWorking(false);
    };

    const copy = async () => {
        setWorking(true);
        const promiseArray: Promise<any>[] = [];
        if (props.type === "creativeSet") {
            editedItems?.forEach((item) => {
                promiseArray.push(
                    adnCopy(globalState.get.token(), props.type, item.id, item.name, selectedFolio?.value)
                );
            });
        } else {
            editedItems?.forEach((item) => {
                promiseArray.push(adnCopy(globalState.get.token(), props.type, item.id, item.name));
            });
        }
        const results = await Promise.all(promiseArray);
        console.log(goTo, selectedFolio?.value);
        props.setShowDialog(false);
        if (goTo && selectedFolio?.value) {
            navigate("/folio/" + selectedFolio.value, { state: { cache: new Date().toTimeString() } });
        }
        props.onActionSuccess && props.onActionSuccess();
        setWorking(false);
    };

    const _delete = async () => {
        setWorking(true);
        const promiseArray: Promise<any>[] = [];
        if (props.type === "creativeSet") {
            editedItems?.forEach((item) => {
                promiseArray.push(adnDeleteCreativeSet(globalState.get.token(), item.id));
            });
        } else if (props.type === "folio") {
            editedItems?.forEach((item) => {
                promiseArray.push(adnDeleteFolio(globalState.get.token(), item.id));
            });
        }
        const results = await Promise.all(promiseArray);
        props.setShowDialog(false);
        props.onActionSuccess && props.onActionSuccess();
        setWorking(false);
    };

    const handleSubmit = () => {
        switch (props.action) {
            case "copy":
                copy();
                break;
            case "rename":
                rename();
                break;
            case "delete":
                _delete();
                break;
        }
    };

    const handleCloseClick = (e: any) => {
        if (e.target.classList.contains("dialog-container")) {
            setEditedItems(null);
            props.setVisible();
        }
    };

    const handleChange = (index: number, name: string) => {
        if (editedItems) {
            const items = [...editedItems];
            items[index].name = name;
            setEditedItems(items);
        }
    };

    useEffect(() => {
        if (props.items.length !== 0 && editedItems === null) {
            const newItems = structuredClone(props.items);
            if (props.action === "copy") {
                const renamedNewItems = newItems.map((item: any) => {
                    return { ...item, name: `Copy of ${item.name}` };
                });
                setEditedItems(renamedNewItems);
            } else if (props.action === "rename") {
                setEditedItems(newItems);
            } else if (props.action === "delete") {
                setEditedItems(newItems);
            }
        }
    }, [editedItems, props.action, props.items]);

    useEffect(() => {
        if (!moveCheck) {
            setSelectedFolio(null);
        }
    }, [moveCheck]);
    return (
        <div className="dialog-container" onClick={handleCloseClick}>
            {editedItems !== null && !!editedItems.length && (
                <div className="dialog">
                    <div className={`header ${props.action === "delete" ? "warning" : ""}`}>
                        <h2>
                            {props.action} {props.type === "folio" ? "folio" : "creative set"}
                        </h2>
                    </div>
                    <div className="description">
                        <Description items={props.items} action={props.action} type={props.type} />
                    </div>
                    {props.action === "copy" && props.type === "creativeSet" && (
                        <div className={`copy-move ${moveCheck ? "open" : ""}`}>
                            <Checkbox
                                checked={moveCheck}
                                onCheck={() => {
                                    setMoveCheck(!moveCheck);
                                }}
                                label={`Move new creative set${editedItems.length > 1 ? "s" : ""} to other folio?`}
                                labelAlsoChecks
                            />
                            {moveCheck && (
                                <OmniSearch
                                    types={["Folio"]}
                                    onSelect={handleSearchSelect}
                                    placeholder="Type to search for a folio..."
                                />
                            )}
                            {selectedFolio && (
                                <Checkbox
                                    checked={goTo}
                                    onCheck={() => {
                                        setGoTo(!goTo);
                                    }}
                                    label="Go to chosen folio after copy"
                                    labelAlsoChecks
                                    checkBoxPosition="right"
                                    align="right"
                                />
                            )}
                        </div>
                    )}
                    <ul>
                        {props.action === "rename" && (
                            <RenameList items={props.items} editedItems={editedItems} onChange={handleChange} />
                        )}
                        {props.action === "copy" && (
                            <CopyList items={props.items} editedItems={editedItems} onChange={handleChange} />
                        )}
                        {props.action === "delete" && <DeleteList items={props.items} />}
                    </ul>

                    <div className="buttons row">
                        <div className="button cancel" onClick={() => props.setShowDialog(false)}>
                            Cancel
                        </div>
                        <div className={`button submit ${working ? "disabled" : ""}`} onClick={handleSubmit}>
                            {working ? "Loading..." : "Confirm"}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
