export const sizes = {
    IAB: [
        {
            name: "180x500",
            width: 180,
            height: 500,
        },
        {
            name: "300x250",
            width: 300,
            height: 250,
        },
        {
            name: "300x600",
            width: 300,
            height: 600,
        },
        {
            name: "320x250",
            width: 320,
            height: 250,
        },
        {
            name: "320x400",
            width: 320,
            height: 400,
        },
        {
            name: "580x400",
            width: 580,
            height: 400,
        },
        {
            name: "980x300",
            width: 980,
            height: 300,
        },
        {
            name: "980x600",
            width: 980,
            height: 600,
        },
        {
            name: "1080x1920",
            width: 1080,
            height: 1920,
        },
        {
            name: "1920x1080",
            width: 1920,
            height: 1080,
        },
        {
            name: "1920x1080 (with overlay)",
            width: 1920,
            height: 1080,
        },
        {
            name: "Custom",
        },
    ],
};
