import { renderToStaticMarkup } from "react-dom/server";
import { BiError } from "react-icons/bi";
import { Tabulator as TabulatorTypes } from "react-tabulator/lib/types/TabulatorTypes";

export const nameFormatter: TabulatorTypes.Formatter = (cell: any, formatterParams: any, onRendered: any) => {
    let returnString = "";
    const val = cell.getValue();
    if (!val) {
        returnString = renderToStaticMarkup(
            <BiError style={{ color: "#fb7188" }} size={18} data-tooltip-id="tt-name-empty" />
        );
    } else if (val) {
        returnString = val;
    }
    return returnString;
};
