import { CgCheckO, CgSpinnerTwo } from "react-icons/cg";

export const Popup = (props: { success: boolean; loadingText: any; successText: any; progress?: number }) => {
    return (
        <div className="create-popup">
            {!props.success && (
                <>
                    <CgSpinnerTwo className="icon spin" size="10rem" />
                    {props.loadingText}
                </>
            )}
            {props.success && (
                <>
                    <CgCheckO size="10rem" className="icon success" />
                    {props.successText}
                </>
            )}
            {props.progress && (
                <>
                    <div className="upload-progress-container">
                        <div className="upload-progress-completion" style={{ width: `${props.progress}%` }}></div>
                    </div>
                </>
            )}
        </div>
    );
};
