export const Details = (props: { stats: any }) => {
    return (
        <div className="details">
            {props.stats && (
                <>
                    <div className="row">
                        <div className="row space-between stat">
                            <span>Impressions</span>
                            <span className="value">{props.stats.totals.impressions}</span>
                        </div>
                        <div className="row space-between stat">
                            <span>Viewable impressions</span>
                            <span className="value">{props.stats.totals.viewables}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row space-between stat">
                            <span>Clicks</span>
                            <span className="value">{props.stats.totals.clicks}</span>
                        </div>
                        <div className="row space-between stat">
                            <span>Click-through rate</span>
                            <span className="value">{props.stats.totals.ctr.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row space-between stat">
                            <span>Unique users</span>
                            <span className="value">{props.stats.totals.uniqueUsers}</span>
                        </div>
                        <div className="row space-between stat">
                            <span>Impressions/unique user</span>
                            <span className="value">{props.stats.totals.impressionsPerUniqueUser.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row space-between stat">
                            <span>Viewability</span>
                            <span className="value">{(props.stats.totals.viewability * 100).toFixed(2)}%</span>
                        </div>
                        <div className="row space-between stat"></div>
                    </div>
                </>
            )}
        </div>
    );
};
