import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/tools.css";
import { IconButton } from "./IconButton";
import { IoSearchCircleOutline } from "react-icons/io5";
import { Search } from "./Search";

export const Tools = (props: { user: any; setToken: any }) => {
    const [activeTool, setActiveTool] = useState<false | "search">(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.user.user.username !== "adnuntius@adnuntius.com") {
            navigate("/");
        }
    }, [props.user, navigate]);
    return (
        <div className="tools">
            <div className="container">
                <div className="title">Tools</div>
                <div className="content">
                    <div className="icon-buttons">
                        <IconButton
                            title="Search"
                            icon={<IoSearchCircleOutline />}
                            onClick={() => {
                                setActiveTool("search");
                            }}
                            active={activeTool === "search"}
                        />
                    </div>
                    {activeTool === "search" && <Search />}
                </div>
            </div>
        </div>
    );
};
