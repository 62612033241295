import { useEffect, useState } from "react";
import "../../css/manage.css";
import { Advertisers } from "./Advertisers";
import { AdvertiserView } from "./AdvertiserView";
import { useLocation, useParams } from "react-router-dom";
import { CreativeSet } from "./CreativeSet";
import { FolioView } from "./FolioView";

export const Manage = (props: { setToken: any; user: any }) => {
    const [aid, setAid] = useState<any>(undefined);
    const [cid, setCid] = useState<any>(undefined);
    const [fid, setFid] = useState<any>(undefined);
    const params = useParams();

    useEffect(() => {
        setAid(false);
        setCid(false);
        setFid(false);
        if (params.advertiserId) {
            setAid(params.advertiserId);
        } else if (params.creativeSetId) {
            setCid(params.creativeSetId);
        } else if (params.folioId) {
            setFid(params.folioId);
        } else {
            setAid(undefined);
        }
    }, [params]);

    return (
        <div className="manage">
            {!aid && !cid && !fid && <Advertisers setSelectedAdvertiser={setAid} setFolioId={setFid} />}
            {fid && <FolioView folioId={fid} />}
            {aid && <AdvertiserView user={props.user} advertiserId={aid} />}
            {cid && <CreativeSet creativeSetId={cid} />}
        </div>
    );
};
