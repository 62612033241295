import { useContext, useEffect, useState } from "react";
import { adnAsset } from "../functions/adnuntius/asset";
import { GlobalStateManager, useGlobalState } from "../contexts/useGlobalState";
import { AdnuntiusAsset, AdnuntiusAssets } from "../components/types/AdnuntiusAsset";

export const useAssets = (creativeId: string): AdnuntiusAssets | undefined => {
    const [assets, setAssets] = useState<AdnuntiusAssets | undefined>(undefined);
    const [fetching, setFetching] = useState<boolean>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    useEffect(() => {
        const fetchAssets = async (creativeId: string) => {
            setFetching(true);
            let fetchedAssets: any = false;
            try {
                fetchedAssets = await adnAsset(globalState.get.token(), creativeId);
                console.log(fetchedAssets);
            } catch (e) {
                console.warn("Fetch error", e);
                globalState.set.token("");
            }
            setAssets(fetchedAssets);
            setFetching(false);
        };

        if (!assets && !fetching) {
            fetchAssets(creativeId);
        }
    }, [assets, creativeId, fetching, globalState.get, globalState.set]);

    return assets;
};
