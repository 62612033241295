import { ChangeEvent, useContext, useEffect, useState } from "react";
import { adnCreativeSets } from "../../functions/adnuntius/creativeSets";
import { CreativeSetCreator } from "./CreativeSetCreator";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { BsBriefcase } from "react-icons/bs";

export const CreativeSetPicker = (props: {
    folioId: string;
    setCreativeSet: any;
    open: any;
    order: any;
    advertiser: any;
}) => {
    const [creativeSets, setCreativeSets] = useState<any>(false);
    const [filteredCreativeSets, setFilteredCreativeSets] = useState<any>([]);
    const [view, setView] = useState<"search" | "create">("search");
    const [query, setQuery] = useState("");
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.currentTarget.value);
    };

    useEffect(() => {
        if (creativeSets) {
            const filtered = creativeSets.results.filter((o: any) => o.name.toLowerCase().includes(query));
            setFilteredCreativeSets(filtered);
        }
    }, [query, creativeSets]);

    const title = props.order ? (
        <>
            <span className="bold">
                <BsBriefcase />
            </span>
            <span className="order-name">{props.order.name}</span>
        </>
    ) : (
        <>
            <span>Select a creative set</span>
            {props.open && (
                <span
                    className="new-btn button"
                    onClick={() => {
                        setQuery("");
                        setView("create");
                    }}>
                    new
                </span>
            )}
        </>
    );
    const fetchCreativeSets = async () => {
        const o = await adnCreativeSets(globalState.get.token(), { folioId: props.folioId });
        if (!o?.results) {
            globalState.set.token("");
        }
        setCreativeSets(o);
        o?.results ? setFilteredCreativeSets(o.results) : setFilteredCreativeSets([]);
    };

    useEffect(() => {
        if (props.open && !creativeSets && creativeSets.length < 1) {
            fetchCreativeSets();
            setFilteredCreativeSets([]);
        }
    }, [props.folioId, globalState.get, globalState.set, props.open, creativeSets]);

    useEffect(() => {
        if (props.folioId && props.open) {
            fetchCreativeSets();
        }
    }, [props.folioId, props.open]);
    return (
        <div className={`order-picker ${props.open ? "open" : ""}`}>
            {!props.open && (
                <h2
                    onClick={() => {
                        props.setCreativeSet(false);
                        setCreativeSets(false);
                        setFilteredCreativeSets([]);
                    }}
                    className="clickable">
                    {title}
                </h2>
            )}
            {view === "search" && (
                <>
                    {props.open && <h2 className="uncentered">{title}</h2>}
                    <input
                        type="text"
                        name="creativeset-filter"
                        id="creativeset-filter"
                        placeholder="Search creative sets"
                        onChange={onSearch}
                        tabIndex={props.open ? 0 : -1}
                    />
                    {creativeSets && (
                        <ul>
                            {filteredCreativeSets.map((o: any, i: number) => (
                                <li onClick={() => props.setCreativeSet(o)} key={i} tabIndex={0}>
                                    <BsBriefcase /> {o.name}
                                </li>
                            ))}
                            {filteredCreativeSets.length === 0 && (
                                <li tabIndex={1} onClick={() => setView("create")}>
                                    Create <span style={{ fontStyle: "italic" }}>{query}?</span>
                                </li>
                            )}
                        </ul>
                    )}
                </>
            )}
            {view === "create" && (
                <CreativeSetCreator
                    name={query}
                    folioId={props.folioId}
                    advertiser={props.advertiser}
                    setView={() => setView("search")}
                    setCreativeSet={(creativeSet: any) => props.setCreativeSet(creativeSet)}
                    clearCreativeSets={() => {
                        props.setCreativeSet(false);
                        setQuery("");
                    }}
                />
            )}
        </div>
    );
};
