import { useContext, useState } from "react";
import { adnFetch } from "../../functions/adnuntius/fetch";
import { useGlobalState, GlobalStateManager } from "../../contexts/useGlobalState";

type UsersObject = {
    Name: string;
    Network: string;
    Link: any;
};
export const Search = (props: any) => {
    const [results, setResults] = useState<Array<any>>([]);
    const [type, setType] = useState<string>("users");
    const [field, setField] = useState<string>("name");
    const [value, setValue] = useState<string>("");
    const [hidden, setHidden] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setResults([]);
        setSearching(true);
        const networks = await adnFetch("/v1/networks", {
            urlParams: { pageSize: "999" },
            token: globalState.get.token(),
        });
        const searchPromiseArray = networks.results.map((nw: any) => searchNetwork(nw));
        const searchResults = await Promise.all(searchPromiseArray);
        const res: any[] = [];
        searchResults.forEach((result) => {
            if (result.totalCount !== 0) {
                const nw = result.network;
                result.results.forEach((r: any) => {
                    res.push(setResultObject(r, nw));
                });
            }
        });
        setResults(res);
        setSearching(false);
    };

    const setResultObject = (result: any, network: any) => {
        switch (type) {
            case "users":
                const userObject: UsersObject = {
                    Name: result.name,
                    Network: network.id,
                    Link: getItemUrl(type, result.id),
                };
                return userObject;
            case "sites":
                break;
            case "adunits":
                break;
            case "layouts":
                break;
            case "lineitems":
                break;
            case "orders":
                break;
            case "advertisers":
                break;
        }
    };

    const searchNetwork = async (nw: any) => {
        let path = "";
        let options: any = {};
        if (field === "autagid") {
            path = `/v1/${type}`;
            options = { token: globalState.get.token(), urlParams: { pageSize: "9999" } };
        } else {
            path = `/v1/${type}`;
            options = {
                token: globalState.get.token(),
                urlParams: {
                    pageSize: "9999",
                    filterBy: field,
                    filterByLike: value,
                    includeAll: "true",
                    includeInactive: "true",
                },
            };
        }
        if (hidden) {
            options.urlParams["includeHidden"] = "true";
        }
        options.urlParams["context"] = nw.id;
        const result = await adnFetch(path, options);
        result["network"] = nw;
        return result;
    };

    const getItemUrl = (type: string, id: string) => {
        let path;
        switch (type) {
            case "users":
                path = "admin/users/user";
                break;
            case "sites":
                path = "sites/site";
                break;
            case "adunits":
                path = "ad-units/ad-unit";
                break;
            case "layouts":
                path = "layouts/layout";
                break;
            case "lineitems":
                path = "line-items/line-item";
                break;
            case "orders":
                path = "orders/order";
                break;
            case "advertisers":
                path = "advertisers/advertiser";
                break;
        }
        return <a href={`https://admin.adnuntius.com/${path}/${id}`}>Link</a>;
    };

    const handleChange = (e: any) => {
        switch (e.target.name) {
            case "type":
                setType(e.target.value);
                break;
            case "field":
                setField(e.target.value);
                break;
            case "value":
                setValue(e.target.value);
                break;
            case "hidden":
                setHidden(e.target.checked);
                break;
        }
    };
    return (
        <>
            <form className="search-form" onSubmit={handleSubmit}>
                <label htmlFor="type">Search for</label>
                <select name="type" id="type" className="button" onChange={handleChange}>
                    <option value="users">users</option>
                    <option value="sites">sites</option>
                    <option value="adunits">ad units</option>
                    <option value="lineitems">line items</option>
                    <option value="orders">orders</option>
                    <option value="advertisers">advertisers</option>
                    <option value="layouts">layouts</option>
                </select>
                <label htmlFor="field">where the</label>
                <select name="field" id="field" className="button" onChange={handleChange}>
                    <option value="name">name</option>
                    <option value="username">user name (user@domain.tld)</option>
                    <option value="autagid">ad unit tag id</option>
                </select>
                <label htmlFor="value">includes</label>
                <input type="text" name="value" id="value" className="button" onChange={handleChange} />
                <input type="submit" id="btn" value={searching ? `Searching...` : `Search`} className="button" />
                <br />
                <input type="checkbox" id="hidden" name="hidden" onChange={handleChange} />
                <label htmlFor="hidden">Include deleted/hidden</label>
            </form>
            {!!results.length && (
                <div className="results-list">
                    <table>
                        <thead>
                            <tr>
                                {Object.keys(results[0]).map((key, i) => (
                                    <td key={i}>{key}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {results.map((result, i) => (
                                <tr>
                                    {Object.keys(result).map((key, i) => (
                                        <td key={i}>{result[key]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};
