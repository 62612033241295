import { AdnFetchOptions, adnFetch } from "./fetch";

export const adnAdvertisers = async (token: string, teams: string[]) => {
    const opts: AdnFetchOptions = { token: token };
    if (teams.length !== 0) {
        opts.urlParams = {
            concise: "true",
            includeHidden: "false",
            includeInactive: "false",
            pageSize: "999",
            joins: "inner join team on advertiser.ownerTeamId=team.id",
            where: `team.name in ${teams.join(",")}`,
        };
    }
    const advertisers = await adnFetch("/v1/advertisers", opts);
    return advertisers;
};
