import { BsCheckSquare, BsSquare } from "react-icons/bs";

export const Checkbox = (props: {
    checked: boolean;
    label: string;
    onCheck?: any;
    labelAlsoChecks?: boolean;
    checkBoxPosition?: "right" | "left";
    align?: "right" | "left";
}) => {
    const cbPos = props.checkBoxPosition || "left";
    const align = props.align || "left";
    const alignClass = `align-${align}`;
    const CB = (props: { position: "right" | "left"; checked: boolean; onCheck?: any }) => {
        return props.checked ? (
            <BsCheckSquare onClick={props.onCheck} className={props.position} />
        ) : (
            <BsSquare onClick={props.onCheck} className={props.position} />
        );
    };
    return (
        <div className={`checkbox ${alignClass}`}>
            {cbPos === "left" && <CB position={cbPos} checked={props.checked} onCheck={props.onCheck} />}
            <span className="check-label" onClick={props.labelAlsoChecks && props.onCheck}>
                {props.label}
            </span>
            {cbPos === "right" && <CB position={cbPos} checked={props.checked} onCheck={props.onCheck} />}
        </div>
    );
};
