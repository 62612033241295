import { useContext, useState } from "react";
import { GlobalStateManager, useGlobalState } from "../../../contexts/useGlobalState";
import { v4 as uuidv4 } from "uuid";
import { Checkbox } from "../../Checkbox";
import { useNavigate } from "react-router-dom";
import { adnPostAdvertiser } from "../../../functions/adnuntius/advertiser";
import { adnPostCreativeSet } from "../../../functions/adnuntius/creativeSet";

export const CreateCreativeSet = (props: { user: any; folioId: string; onCreateCreativeSet: any; setVisible: any }) => {
    const [creativeSetName, setCreativeSetName] = useState<string>("");
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const navigate = useNavigate();

    const handleCheck = () => {
        setIsChecked(!isChecked);
    };

    const handleChangeCreativeSetName = (e: any) => {
        setCreativeSetName(e.target.value);
    };

    const handleCreateCreativeSet = async () => {
        const creativeSet = await adnPostCreativeSet(globalState.get.token(), uuidv4(), {
            name: creativeSetName,
            folioId: props.folioId,
        });
        if (creativeSet.id) {
            props.setVisible();
            props.onCreateCreativeSet();
            if (isChecked) {
                navigate("/creativeset/" + creativeSet.id);
            }
        }
    };
    return (
        <>
            <label htmlFor="creativeSet-name">Creative Set name</label>
            <input
                type="text"
                name="creativeSet-name"
                id="creativeSet-name"
                onChange={handleChangeCreativeSetName}
                value={creativeSetName}
                autoFocus
            />
            <div className="row end">
                <div className="button" onClick={handleCreateCreativeSet}>
                    Create
                </div>
            </div>
            <Checkbox checked={isChecked} label="Go to creative set after creation" onCheck={handleCheck} />
        </>
    );
};
