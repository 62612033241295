import { BsBriefcase, BsFolder2Open } from "react-icons/bs";
import { Item } from "./Item";
import { useContext, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Tooltip } from "react-tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import { adnFolio } from "../../functions/adnuntius/folios";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { adnCreativeSets } from "../../functions/adnuntius/creativeSets";
import { Create } from "./Create/Create";
import { useUser } from "../../contexts/useUser";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { by } from "../../functions/sort";
import { Copy } from "./Copy/Copy";
import { Toolbox } from "./toolbox/Toolbox";
import { helpParser } from "../../functions/helpParser";

export const FolioView = (props: { folioId: string }) => {
    const [creativeSets, setCreativeSets] = useState<any[] | null>([]);
    const [fetchingCreativeSets, setFetchingCreativeSets] = useState<boolean>(false);
    const [filteredCreativeSets, setFilteredCreativeSets] = useState<any[]>([]);
    const [selectedCreativeSets, setSelectedCreativeSets] = useState<any[]>([]);
    const [query, setQuery] = useState<string>("");
    const [folio, setFolio] = useState<any>(null);
    const [fetchingFolio, setFetchingFolio] = useState<boolean>(false);
    const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
    const [currentFilter, setCurrentFilter] = useState<"name" | "date">("name");
    const [filteredByDate, setFilteredByDate] = useState<false | "asc" | "desc">(false);
    const [filteredByName, setFilteredByName] = useState<false | "asc" | "desc">(false);
    const [editActive, setEditActive] = useState<boolean>(false);
    const user = useContext(useUser);
    const location = useLocation();

    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/advertiser/" + folio.advertiser.id);
    };

    const handleCampaignClick = (creativeSetId: string) => {
        navigate("/creativeset/" + creativeSetId);
    };

    const toggleIsCreateVisible = () => {
        setIsCreateVisible(!isCreateVisible);
    };

    const handleSearch = (e: any) => {
        setQuery(e.target.value);
    };

    const handleSort = (value: "date" | "name") => {
        setCurrentFilter(value);
        if (value === "date") {
            if (filteredByDate) {
                switch (filteredByDate) {
                    case "desc":
                        setFilteredByDate("asc");
                        break;
                    case "asc":
                        setFilteredByDate(false);
                        break;
                }
            } else {
                setFilteredByDate("desc");
            }
        }
        if (value === "name") {
            if (filteredByName) {
                switch (filteredByName) {
                    case "desc":
                        setFilteredByName("asc");
                        break;
                    case "asc":
                        setFilteredByName(false);
                        break;
                }
            } else {
                setFilteredByName("desc");
            }
        }
    };
    const SortIcon = (props: { method: "asc" | "desc" | false; style: React.CSSProperties }) => {
        if (!props.method) {
            return <FaSort size={12} style={props.style} />;
        }
        switch (props.method) {
            case "desc":
                return <FaSortDown size={12} style={props.style} />;
            case "asc":
                return <FaSortUp size={12} style={props.style} />;
        }
    };

    const handleCheck = (index: number) => {
        const newCreativeSets = filteredCreativeSets.map((cs: any, i: number) => {
            if (i === index) {
                return { ...cs, checked: true };
            } else {
                return cs;
            }
        });
        setFilteredCreativeSets(newCreativeSets);
    };

    const handleUncheck = (index: number) => {
        const newCreativeSets = filteredCreativeSets.map((cs: any, i: number) => {
            if (i === index) {
                return { ...cs, checked: false };
            } else {
                return cs;
            }
        });
        setFilteredCreativeSets(newCreativeSets);
    };

    const handleEditSuccess = () => {
        setCreativeSets([]);
        setSelectedCreativeSets([]);
        setFolio(null);
    };

    // Get data
    useEffect(() => {
        const fetchFolio = async () => {
            setFetchingFolio(true);
            const r = await adnFolio(globalState.get.token(), props.folioId);
            if (r?.code === "error.unauthorised") {
                globalState.set.token("");
            }
            setFolio(r);
            setFetchingFolio(false);
        };
        const fetchCreativeSets = async () => {
            setFetchingCreativeSets(true);
            const r = await adnCreativeSets(globalState.get.token(), { folioId: folio.id });
            if (r?.code === "error.unauthorised") {
                globalState.set.token("");
            }
            const setsWithCheckStatus = r.results.map((r: any) => {
                return { ...r, checked: false };
            });
            if (setsWithCheckStatus.length === 0) {
                setCreativeSets(null);
            } else {
                setCreativeSets(setsWithCheckStatus);
                setFilteredCreativeSets(setsWithCheckStatus);
                setFetchingCreativeSets(false);
            }
        };
        if (!folio && !fetchingFolio) {
            fetchFolio();
        }
        if (creativeSets && creativeSets.length === 0 && folio && !fetchingCreativeSets) {
            fetchCreativeSets();
        }
    }, [creativeSets, fetchingCreativeSets, fetchingFolio, folio, globalState.get, globalState.set, props.folioId]);

    // Resets everything on url redirect to new folio
    useEffect(() => {
        setCreativeSets([]);
        setFetchingCreativeSets(false);
        setFolio(null);
        setFetchingFolio(false);
    }, [props.folioId]);

    useEffect(() => {
        if (location.state?.cache) {
            setFolio(null);
            setCreativeSets([]);
            console.log("emptying!");
        }
    }, [location.state]);
    useEffect(() => {
        if (creativeSets) {
            if (query !== "") {
                setFilteredCreativeSets(creativeSets.filter((cs) => cs.name.includes(query)));
            } else {
                setFilteredCreativeSets(creativeSets);
            }
        }
    }, [creativeSets, query]);

    useEffect(() => {
        const checkedItems = filteredCreativeSets.filter((cs) => cs.checked);
        if (checkedItems.length !== 0) {
            setSelectedCreativeSets(checkedItems);
        } else {
            setSelectedCreativeSets([]);
        }
    }, [filteredCreativeSets]);

    useEffect(() => {
        if (creativeSets && creativeSets.length !== 0) {
            if (currentFilter === "date") {
                if (!filteredByDate) {
                    const sortedCSArr = [...creativeSets];
                    sortedCSArr.sort((a: any, b: any) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    setFilteredCreativeSets(sortedCSArr);
                }
                if (filteredByDate) {
                    const sortedCSArr = [...creativeSets];
                    switch (filteredByDate) {
                        case "asc":
                            sortedCSArr.sort(by("startDate").direction("ascending"));
                            break;
                        case "desc":
                            sortedCSArr.sort(by("startDate").direction("descending"));
                            break;
                    }
                    setFilteredCreativeSets(sortedCSArr);
                }
            }
            if (currentFilter === "name") {
                if (filteredByName) {
                    const sortedCSArr = [...creativeSets];
                    switch (filteredByName) {
                        case "asc":
                            sortedCSArr.sort(by("name").direction("ascending"));
                            break;
                        case "desc":
                            sortedCSArr.sort(by("name").direction("descending"));
                            break;
                    }
                    setFilteredCreativeSets(sortedCSArr);
                }
            }
        }
    }, [currentFilter, filteredByDate, filteredByName, creativeSets]);

    return (
        <div className="advertisers">
            <div className="top">
                <div
                    className="add-creativeSet button"
                    onClick={() => {
                        setIsCreateVisible(true);
                    }}>
                    <BsBriefcase
                        style={{
                            float: "left",
                            marginRight: ".5rem",
                            position: "relative",
                            top: ".05rem",
                            fontSize: 16,
                        }}
                    />
                    <span>New</span>
                </div>
                {folio && <Copy name={folio.name} folioId={props.folioId} />}
                <span
                    className="back button"
                    onClick={handleBack}
                    data-tooltip-id="tt-back"
                    data-tooltip-delay-show={800}>
                    <BiArrowBack />
                </span>
                <h2>
                    <div className="crumbs">
                        {folio && (
                            <span
                                onClick={() => {
                                    navigate("/advertiser/" + folio.advertiser.id);
                                }}>
                                {folio.advertiser.name}
                            </span>
                        )}
                    </div>
                    <BsFolder2Open size={32} />
                    {folio ? `${folio.name}` : ""}
                </h2>
                <input
                    type="text"
                    name=""
                    id="creative-filter"
                    placeholder="Search creative set"
                    value={query}
                    onChange={handleSearch}
                    autoFocus
                />
            </div>
            <Tooltip id="tt-back" content="Back to advertiser list" place="right" variant="info" noArrow />
            <Toolbox
                type="creativeSet"
                items={selectedCreativeSets}
                onActionSuccess={handleEditSuccess}
                isOpen={editActive}
                onOpen={() => {
                    setEditActive(!editActive);
                }}
            />
            <div className="list-header">
                <span
                    onClick={() => handleSort("name")}
                    style={{ cursor: "pointer", marginRight: "2rem", marginLeft: "1.5rem" }}>
                    Creative set name <SortIcon method={filteredByName} style={{ transform: "translateY(.1rem)" }} />
                </span>
                <span onClick={() => handleSort("date")} style={{ cursor: "pointer", marginRight: "2rem" }}>
                    Start date <SortIcon method={filteredByDate} style={{ transform: "translateY(.1rem)" }} />
                </span>
            </div>
            <ul>
                {filteredCreativeSets.length !== 0 &&
                    filteredCreativeSets.map((creativeSet: any, i: number) => {
                        return (
                            <li key={i}>
                                <Item
                                    checkable={editActive}
                                    onClick={() => {
                                        handleCampaignClick(creativeSet.id);
                                    }}
                                    handleCheck={() => {
                                        handleCheck(i);
                                    }}
                                    handleUnCheck={() => {
                                        handleUncheck(i);
                                    }}
                                    checked={creativeSet.checked}
                                    value={
                                        <>
                                            <span>{creativeSet.name}</span>
                                            <span>{creativeSet.startDate.split("T")[0]}</span>
                                        </>
                                    }
                                />
                            </li>
                        );
                    })}
            </ul>
            <Create
                type="creativeSet"
                visible={isCreateVisible}
                setVisible={toggleIsCreateVisible}
                onCreateCreativeSet={() => setCreativeSets([])}
                user={user}
                folioId={props.folioId}
            />
            <Tooltip
                anchorSelect=".add-creativeSet"
                render={() => helpParser("Adds a new creative set to this folio.")}
                place="left"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect="#creative-filter"
                render={() => helpParser("Filters the list of creative sets based on your search term")}
                place="bottom"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
        </div>
    );
};
