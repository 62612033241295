import { useContext, useEffect, useState } from "react";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { adnStats } from "../../functions/adnuntius/stats";
import "../../css/stats.css";
import { Details } from "./Details";
import { Graphs } from "./Graphs";

export const Stats = (props: { campaign: any }) => {
    const [stats, setStats] = useState<any>(null);
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const fetchStats = async () => {
        const r = await adnStats(globalState.get.token(), { creativeSetId: props.campaign.id });
        setStats(r);
    };

    useEffect(() => {
        if (!stats) {
            fetchStats();
        }
    }, [stats]);
    return (
        <div className="stats">
            {stats && (
                <div className="main-view">
                    <Details stats={stats} />
                    <Graphs stats={stats} />
                </div>
            )}
        </div>
    );
};
