import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactTabulator } from "react-tabulator";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import "react-tabulator/lib/styles.css";
import { Tabulator, Tabulator as TabulatorTypes } from "react-tabulator/lib/types/TabulatorTypes";
import { Tooltip } from "react-tooltip";
import { Files, GlobalStateManager, useGlobalState } from "../../../contexts/useGlobalState";
import "../../../css/bulk.css";
import { doubleFormatter } from "../../../functions/formatters/doubleFormatter";
import { linkFormatter } from "../../../functions/formatters/linkFormatter";
import { onFallbackRendered, onPreviewRendered } from "../../../functions/formatters/onPreviewRendered";
import { nameFormatter } from "../../../functions/formatters/nameFormatter";
import { helpParser } from "../../../functions/helpParser";
import { Fallback } from "../fallback/Fallback";

interface ColumnDefinition extends TabulatorTypes.ColumnDefinition {}
export const Bulk = (props: any) => {
    const [files, setFiles] = useState<File[]>([]);
    const [rawTableData, setRawTableData] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);
    const [prefix, setPrefix] = useState<any>("");
    const [suffix, setSuffix] = useState<any>("");
    const [clickURL, setClickURL] = useState<any>("");
    const [impTrackerURL, setImpTrackerURL] = useState<any>("");
    const [isValid, setIsValid] = useState<boolean>();
    const [currentFallback, setCurrentFallback] = useState<any>(undefined);
    const [currentFallbackOffset, setCurrentFallbackOffset] = useState<number>(0);
    const [activeCell, setActiveCell] = useState<Tabulator.CellComponent | undefined>(undefined);
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const navigate = useNavigate();
    let tabulatorRef = useRef();

    const handleSubmit = () => {
        const tabData = tabulatorRef.current as any;
        if (tabData) {
            const rows = tabData.rowManager.rows;
            const filesData: Files[] = [];
            rows.forEach((r: Tabulator.RowComponent) => {
                const rowData = r.getData();
                console.log(rowData);
                const fileObj = {
                    file: files[rowData.index],
                    name: rowData.name,
                    fullscreen: rowData.fullscreen,
                    loop: rowData.videoEnd,
                    double: rowData.double,
                    click: rowData.clickurl,
                    impression: rowData.imptracker,
                    blobUrl: rowData.bloburl,
                    width: rowData.double === "Yes" ? rowData.width / 2 : rowData.width,
                    height: rowData.double === "Yes" ? rowData.height / 2 : rowData.height,
                    videoWidth: rowData.width,
                    videoHeight: rowData.height,
                    fallbackImage: rowData.fallbackOffset,
                };
                filesData.push(fileObj);
            });
            props.setFile(false);
            globalState.set.files(filesData);
            navigate("/upload/details");
        }
    };
    const isClickURLInvalid = (url: string) => {
        let isInvalid = false;
        if (url === "" || url.includes("http://") || !url.includes("https://")) {
            isInvalid = true;
        }
        return isInvalid;
    };
    const isNameInvalid = (name: string) => {
        return name === "";
    };

    const validateTable = () => {
        const invalid = tableData.some((row: any) => {
            return isClickURLInvalid(row.clickurl) || isNameInvalid(row.name);
        });
        setIsValid(!invalid);
    };

    const onCellEdited = (cell: TabulatorTypes.CellComponent) => {
        const field = cell.getField();
        const row = cell.getRow();
        if (field === "name") {
            updateRow(row);
        }
        validateTable();
    };

    const onCellClicked = (e: Event, cell: TabulatorTypes.CellComponent) => {
        if (cell.getField() === "double") {
            cell.setValue(cell.getValue() === "Yes" ? "No" : "Yes");
        }
    };

    const updateRow = (row: TabulatorTypes.RowComponent) => {
        const rowData = row.getData();
        const newTableData = [...tableData];
        newTableData[rowData.index] = rowData;
        setTableData(newTableData);
    };

    const handlePrefix = (e: any) => {
        setPrefix(e.target.value);
    };
    const handleSuffix = (e: any) => {
        setSuffix(e.target.value);
    };
    const handleClickURL = (e: any) => {
        setClickURL(e.target.value);
    };
    const handleImpTrackerURL = (e: any) => {
        setImpTrackerURL(e.target.value);
    };

    const handleApplyToAll = () => {
        const newTableData = tableData.map((row: any, i: number) => {
            return {
                ...row,
                name: `${prefix}${rawTableData[i].name}${suffix}`,
                clickurl: clickURL,
                imptracker: impTrackerURL,
            };
        });
        setTableData(newTableData);
    };

    const previewFormatter: TabulatorTypes.Formatter = (cell: any, formatterParams: any, onRendered: any) => {
        onRendered(() => onPreviewRendered(cell, files, addDataToRow));
        const vidEl = document.createElement("video");
        vidEl.muted = true;
        vidEl.classList.add("video-preview");
        return vidEl;
    };
    const fallbackFormatter: TabulatorTypes.Formatter = (cell: any, formatterParams: any, onRendered: any) => {
        onRendered(() => onFallbackRendered(cell, files, addDataToRow));
        const vidEl = document.createElement("video");
        vidEl.muted = true;
        vidEl.classList.add("fallback-preview");
        return vidEl;
    };

    const addDataToRow = (row: any, url: string, dimensions: { width: number; height: number }) => {
        const widthCell = row.getCell("width");
        const heightCell = row.getCell("height");
        const blobUrlCell = row.getCell("bloburl");
        widthCell.setValue(dimensions.width);
        heightCell.setValue(dimensions.height);
        blobUrlCell.setValue(url);
    };

    const handleFallbackClick = (e: any, cell: any) => {
        const data = cell.getData();
        setActiveCell(cell);
        setCurrentFallback(data.bloburl);
        setCurrentFallbackOffset(data.fallbackOffset);
    };

    const handleSetFallbackImage = (n: number) => {
        console.log("set fallback: ", n);
        const cell = activeCell;
        console.log("selected cell: ", cell?.getData());
        if (cell) {
            const row = cell.getRow();
            console.log(row.getData());
            const fbCell = row.getCell("fallbackOffset");
            fbCell.setValue(n);
            updateRow(row);
        }
    };
    useEffect(() => {
        const globalFiles = globalState.get.files();
        // redirect to home if no files
        if (!globalFiles.length) {
            navigate("/");
        }
        if (globalFiles.length && !files.length) {
            setFiles(globalFiles.map((f) => f.file));
        }
        // Files are set
        if (files) {
            const data = files.map((f, i) => {
                return {
                    index: i,
                    fallbackOffset: globalFiles[i].fallbackImage || 0,
                    name: globalFiles[i].name || f.name.replace(/.mp4|.avi|.webm|.mov/i, ""),
                    videoEnd: globalFiles[i].loop || "Forever",
                    fullscreen: globalFiles[i].fullscreen || "No",
                    clickurl: globalFiles[i].click || clickURL,
                    impTrackerURL: globalFiles[i].impression || "",
                    double: globalFiles[i].double || "No",
                    size: Math.round(f.size / 1000).toLocaleString() + " kb",
                };
            });
            setRawTableData(data);
            setTableData(data);
        }
    }, [files, globalState.get, navigate]);

    const tableColumns: ColumnDefinition[] = [
        { title: "Index", field: "index", visible: false },
        { title: "Blob URL", field: "bloburl", visible: false },
        {
            title: "Preview",
            field: "preview",
            formatter: previewFormatter,
            resizable: "header",
            headerSort: false,
            width: 80,
        },
        {
            title: "Fallback offset",
            field: "fallbackOffset",
            visible: false,
        },
        {
            title: "Fallback",
            field: "fallback",
            formatter: fallbackFormatter,
            resizable: "header",
            headerSort: false,
            width: 80,
            cellClick: handleFallbackClick,
        },
        { title: "Name", field: "name", editor: "input", formatter: nameFormatter, resizable: true, headerSort: false },
        {
            title: "Loop",
            field: "videoEnd",
            editor: "select",
            headerSort: false,
            editorParams: {
                values: ["Forever", "3x", "No"],
                defaultValue: "Forever",
                allowEmpty: false,
                showListOnEmpty: true,
                freetext: false,
            },
            width: 70,
        },
        {
            title: "Fullscreen",
            field: "fullscreen",
            editor: "select",
            headerSort: false,
            editorParams: {
                values: ["No", "Yes"],
                defaultValue: "No",
                allowEmpty: false,
                showListOnEmpty: true,
                freetext: false,
            },
            width: 100,
        },
        {
            title: "2x size",
            field: "double",
            formatter: doubleFormatter,
            headerSort: false,
            width: 70,
        },
        {
            title: "Click URL",
            field: "clickurl",
            editor: "input",
            resizable: "header",
            headerSort: false,
            formatter: linkFormatter,
        },
        {
            title: "Impression tracking URL",
            field: "imptracker",
            editor: "input",
            resizable: "header",
            headerSort: false,
            formatter: linkFormatter,
        },
        { title: "Width", field: "width", headerSort: false, width: 60 },
        { title: "Height", field: "height", headerSort: false, width: 60 },
        { title: "File size", field: "size", headerSort: false, width: 90 },
    ];

    return (
        <div className="bulk">
            <Tooltip
                id="tt-link-empty"
                variant="info"
                content="A click URL is required"
                place="right"
                noArrow
                opacity={1}
                style={{ zIndex: 999 }}
            />
            <Tooltip
                id="tt-link-https"
                variant="info"
                content='Links should begin with "https://"'
                place="bottom"
                noArrow
                opacity={1}
                style={{ zIndex: 999 }}
            />
            <Tooltip
                id="tt-name-empty"
                variant="info"
                content="Name is required"
                place="bottom"
                noArrow
                opacity={1}
                style={{ zIndex: 999 }}
            />
            <div className="container">
                <div className="option">
                    <div className="row">
                        <div>
                            <label htmlFor="prefix">Prefix</label>
                            <input type="text" id="prefix" onChange={handlePrefix} value={prefix} />
                        </div>
                        <div>
                            <label htmlFor="suffix">Suffix</label>
                            <input type="text" id="suffix" onChange={handleSuffix} value={suffix} />
                        </div>
                        <div>
                            <label htmlFor="clickurl">Click URL</label>
                            <input
                                className="long"
                                type="text"
                                id="clickurl"
                                onChange={handleClickURL}
                                value={clickURL}
                            />
                        </div>
                        <div>
                            <label htmlFor="imptracker">Impression tracking URL</label>
                            <input
                                className="long"
                                type="text"
                                id="imptracker"
                                onChange={handleImpTrackerURL}
                                value={impTrackerURL}
                            />
                        </div>
                        <input type="button" className="button" value="Apply to all" onClick={handleApplyToAll} />
                    </div>
                    <div className="row"></div>
                </div>
                {tableData && (
                    <ReactTabulator
                        onRef={(r) => (tabulatorRef.current = r.current)}
                        data={tableData}
                        columns={tableColumns}
                        events={{ cellEdited: onCellEdited, cellClick: onCellClicked }}
                        layout="fitColumns"
                    />
                )}
                <div className="row end">
                    <div className={`button continue ${!isValid && "disabled"}`} onClick={handleSubmit}>
                        Continue
                    </div>
                </div>
            </div>
            {currentFallback && (
                <Fallback
                    asPopup
                    open
                    videoSrc={currentFallback}
                    videoElementId={"video"}
                    showSlider
                    onClose={() => {
                        console.log("closing");
                        setCurrentFallback(undefined);
                    }}
                    onSetFallbackImage={handleSetFallbackImage}
                    fallbackImage={currentFallbackOffset}
                />
            )}
            <Tooltip
                anchorSelect="#prefix"
                render={() => helpParser("Adds text in front of every creative name.")}
                style={{ transform: "translateY(-.5rem)" }}
                place="top-start"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect="#suffix"
                render={() => helpParser("Adds text after every creative name.")}
                place="bottom-end"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect="#clickurl"
                render={() => helpParser("A click URL to add to every creative.")}
                place="bottom-start"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect="#imptracker"
                render={() => helpParser("An impression tracking URL to add to every creative.")}
                style={{ transform: "translateY(-.5rem)" }}
                place="top"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect=".tabulator"
                render={() =>
                    helpParser(
                        "A list of all creatives to upload. You can edit names, if the creative will loop, \n whether the video is made for double resolution, the click URL, \n and the impression tracking URL by clicking in the corresponding field."
                    )
                }
                place="bottom"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
        </div>
    );
};
