import { PieChart, Pie, ResponsiveContainer } from "recharts";

export type CompletionData = {
    name: string;
    value: number;
};
export const Completion = (props: { data: CompletionData[] }) => {
    return (
        <div className="completion">
            <h4>Viewing completion</h4>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={730} height={250} margin={{ top: 40 }}>
                    <Pie
                        data={props.data}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={50}
                        fill="#8884d8"
                        stroke="none"
                        startAngle={180}
                        endAngle={0}
                    />
                </PieChart>
            </ResponsiveContainer>
            <p className="percentage">{props.data[0].value.toFixed(1)}%</p>
        </div>
    );
};
