import CodeMirror from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";
import { tags } from "../../functions/tags";
import Select from "react-select";
import { useContext, useEffect, useState } from "react";
import { BiCopy, BiDownload } from "react-icons/bi";
import { useUser } from "../../contexts/useUser";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { generateHtmlFile } from "../../functions/generateHtmlFile";
import { DownloadZip } from "./DownloadZip";

export const TagPicker = (props: {
    creativeSet?: any;
    creativeSets?: { name: string; id: string }[];
    creatives?: any;
    advertiserName?: string;
    tag?: string;
}) => {
    const [tagOptions, setTagOptions] = useState<{ value: string; label: string; tag: string }[] | []>([]);
    const [tag, setTag] = useState<string>("");
    const [tagType, setTagType] = useState<{ value: string; label: string; tag: string } | undefined>(undefined);
    const [buttonText, setButtonText] = useState<any>("Copy");
    const [buttonHighlight, setButtonHighlight] = useState<boolean>(false);
    const [tagLink, setTagLink] = useState<string>("");
    const [isCsv, setIsCsv] = useState<boolean>(false);
    const user = useContext(useUser);
    const menuStyle = { menu: (style: any) => ({ ...style, zIndex: 100, color: "#00000088" }) };
    const itemState = {
        option: (state: any) => {
            let c = "tag-item";
            c = state.isFocused ? "tag-item-focused" : c;
            c = state.isSelected ? "tag-item-selected" : c;
            return c;
        },
    };

    const handleSelectTagType = (e: any) => {
        setTagType(e);
        localStorage.setItem("tagType", e.value);
    };

    const handleCopy = () => {
        if (tag) {
            navigator.clipboard.writeText(tag);
            setButtonText("Copied!");
            setButtonHighlight(true);
            setTimeout(() => {
                setButtonText("Copy");
                setButtonHighlight(false);
            }, 1500);
        }
    };

    useEffect(() => {
        if (props.tag && tag === "" && !tagType) {
            // set the tag type to what's on the campaigns label
            const filteredTag = tagOptions.filter((tag) => tag.value === props.tag);
            if (filteredTag.length !== 0) {
                setTagType(filteredTag[0]);
                setTag(filteredTag[0].tag);
                return;
            }
        }
        if (!tagType) {
            if (tagOptions.length !== 0) {
                const localTagType = localStorage.getItem("tagType");
                if (localTagType) {
                    const selectedTag = tagOptions.filter((tag) => tag.value === localTagType)[0];
                    setTagType(selectedTag);
                    return;
                }
                setTag(tagOptions[0].tag);
                setTagType(tagOptions[0]);
            }
        }
        if (props.creativeSet) {
            // Single campaign
            if (tagType && tag === "") {
                setTag(tagType.tag);
            } else if (tagOptions.length !== 0 && !tagType) {
                setTag(tagOptions[0].tag);
                setTagType(tagOptions[0]);
            }
        }
        setIsCsv(false);
        if (props.creativeSets && tagType && props.creatives.length === 0) {
            // multiple campaigns
            let t = "";
            t += `<!-- --- --- --- --- --- --- --- --- --- -->\n`;
            t += `<!-- Tags for ${props.advertiserName} -->\n`;
            t += `<!-- --- --- --- --- --- --- --- --- --- -->\n\n`;
            props.creativeSets.forEach((c, i: number) => {
                const tagsArray = tags(c.id, props.creativeSet.id);
                const [selectedTag] = tagsArray.filter((t) => t.value === tagType?.value);
                t += `<!-- ${c.name}: -->\n`;
                t += `${selectedTag.tag}`;
                t += i < props.creativeSets!.length - 1 ? "\n\n" : "";
            });
            setTag(t);
        } else if (props.creatives && props.creativeSet.length !== 0 && tagType && tagType.value === "dv360-csv") {
            let t = `Creative name,Dimensions (width x height),Third-party tag,Landing page URL (Optional),Expanding direction,"Expands on hover (""Yes"" or ""No"")","Requires HTML5 (""Yes"" or ""No"")","Requires MRAID (""Yes"" or ""No"")",Campaign Manager 360 Tracking Placement ID,"Requires ping for attribution (""Yes"" or ""No"")",Integration code (Optional),Notes (Optional)\n`;
            props.creatives.forEach((c: any, i: number) => {
                const tagsArray = tags(c.id, props.creativeSet.id);
                const [selectedTag] = tagsArray.filter((t) => t.value === tagType?.value);
                t += `${c.name},`; // creative name
                t += `${c.width} x ${c.height},`; // dimensions
                t += `${selectedTag.tag},`; // third-party tag
                t += `${c.constraintsToUrls.destination},`; // Landing page url
                t += `,`; // Expanding direction
                t += `"No",`; // "Expands on hover (""Yes"" or ""No"")"
                t += `"No",`; // "Requires HTML5 (""Yes"" or ""No"")"
                t += `"No",`; // "Requires MRAID (""Yes"" or ""No"")"
                t += `,`; // Campaign Manager 360 Tracking Placement ID
                t += `"No",`; // "Requires ping for attribution (""Yes"" or ""No"")"
                t += `,`; // Integration code (Optional)
                t += ``; // Notes (Optional)
                t += `\n`;
            });
            setIsCsv(true);
            setTag(t);
        } else if (props.creatives && props.creatives.length !== 0 && tagType) {
            let t = "";
            t += `<!-- ---------------------------------------\n`;
            t += `  Tags for ${props.advertiserName}\n`;
            t += `  Campaign: ${props.creativeSet.name}\n`;
            t += `---------------------------------------- -->\n\n`;
            props.creatives.forEach((c: any, i: number) => {
                const tagsArray = tags(c.id, props.creativeSet.id);
                const [selectedTag] = tagsArray.filter((t) => t.value === tagType?.value);
                t += `<!-- ---------------------------------------\n`;
                t += `  Creative: ${c.name} (${c.width}x${c.height})\n`;
                t += `---------------------------------------- -->\n`;
                t += `${selectedTag.tag}\n`;
                t += `<!-- ----------------------------------- -->\n\n`;
                t += i < props.creatives!.length - 1 ? "\n" : "";
            });
            setTag(t);
        }
        if (props.creatives && props.creatives.length !== 0 && tagType?.value === "gcm-zip") {
            setTag("");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagType, tagOptions, props.tag, props.creativeSet, props.creativeSets, props.creatives, props.advertiserName]);

    useEffect(() => {
        if (tag) {
            const createTagLink = () => {
                const blob = new Blob([tag], { type: "text/plain;charset=utf-8" });
                return URL.createObjectURL(blob);
            };
            setTagLink(createTagLink());
        }
    }, [tag]);
    useEffect(() => {
        const createTagLink = () => {
            const blob = new Blob([tag], { type: "text/plain;charset=utf-8" });
            return URL.createObjectURL(blob);
        };
        setTagLink(createTagLink());
        // get tag type from localstorage
        const localTagType = localStorage.getItem("tagType");
        if (localTagType) {
            const selectedTag = tagOptions.filter((tag) => tag.value === localTagType)[0];
            setTagType(selectedTag);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props?.creativeSet) {
            setTagOptions(tags(props.creativeSet.id, props.creativeSet.id, user.teams[0].id));
        } else if (props?.creativeSets) {
            // let t = "";
            // props.campaigns.forEach((c, i: number) => {
            //     t += `<!-- Tags for ${props.advertiserName} -->\n\n`;
            //     t += `<!-- ${c.name}: -->`;
            //     t += tags(c.id);
            //     t += i < props.campaigns!.length - 1 ? "\n\n" : "";
            // });
            setTagOptions(tags(props.creativeSets[0].id, props.creativeSet.id));
        }
    }, [props.creativeSet, props.creativeSets, user.teams]);

    return (
        <div className="tag-picker">
            <div className="row start">
                <Select
                    options={tagOptions}
                    value={tagType}
                    onChange={handleSelectTagType}
                    styles={menuStyle}
                    classNames={itemState}
                    className="select-tag"
                />
            </div>
            {tagType?.value !== "gcm-zip" && (
                <>
                    <CodeMirror
                        className="codemirror-tag"
                        value={tag}
                        extensions={[html()]}
                        basicSetup={{ lineNumbers: false, foldGutter: false }}
                        readOnly
                    />
                    <div className="row end">
                        <a
                            className="button download-tag"
                            onClick={handleCopy}
                            href={tagLink}
                            download={`${props?.creativeSet?.name || props?.advertiserName}.${isCsv ? "csv" : "txt"}`}>
                            <BiDownload style={{ marginRight: ".5rem" }} />
                            Download
                        </a>
                        <div
                            className={buttonHighlight ? "button copy-tag highlight" : "button copy-tag"}
                            onClick={handleCopy}>
                            <BiCopy style={{ marginRight: ".5rem" }} />
                            {buttonText}
                        </div>
                    </div>
                </>
            )}
            {tagType?.value === "gcm-zip" && props.creativeSet && (
                <DownloadZip
                    creativeSetId={props.creativeSet.id}
                    creativeSetName={props.creativeSet.name}
                    creatives={props.creatives}
                />
            )}
        </div>
    );
};
