export const studioParser = (labelsArray: string[]) => {
    const studioObject: { [key: string]: string } = {};
    labelsArray.forEach((label) => {
        if (label.includes("studio")) {
            const keyPart = label.split("studio-")[1];
            const key = keyPart.split(":")[0];
            const value = keyPart.split(":")[1];
            studioObject[key] = value;
        }
    });
    return studioObject;
};
