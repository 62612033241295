import Select from "react-select";
import { sizes } from "./sizes";

export type SelectedSize = { label: string; value: string };

export const IABSelect = (props: { cropId: number; selectedSize: undefined | SelectedSize; handleSelectSize: any }) => {
    const opts = sizes.IAB.map((s: any) => {
        return {
            label: `${s.name}`,
            value: s.name,
        };
    });
    const itemState = {
        option: (state: any) => {
            let c = "iab-item";
            c = state.isFocused ? "iab-item-focused" : c;
            c = state.isSelected ? "iab-item-selected" : c;
            return c;
        },
    };
    const menuStyle = { menu: (style: any) => ({ ...style, zIndex: 100, color: "#00000088" }) };
    return (
        <Select
            placeholder="Select size"
            options={opts}
            onChange={(e) => props.handleSelectSize(e, props.cropId)}
            className="size-select"
            classNames={itemState}
            menuPlacement="auto"
            value={props.selectedSize}
            styles={menuStyle}
        />
    );
};
