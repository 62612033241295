import { AdnFetchOptions, adnFetch } from "./fetch";

export const adnFolios = async (token: string, advertiserId: string) => {
    const folios = await adnFetch("/v1/folios", {
        token: token,
        urlParams: {
            pageSize: "999",
            includeHidden: "false",
            includeInactive: "false",
            orderBy: "name",
            orderByDirection: "ASCENDING",
            advertiser: advertiserId,
        },
    });
    return folios;
};

export interface FolioOptions {
    advertiserId: string;
    name: string;
    teamId: string;
}
export const adnPostFolio = async (token: string, folderId: string, opts: FolioOptions) => {
    const body = {
        advertiser: {
            id: opts.advertiserId,
        },
        name: opts.name,
        team: {
            id: opts.teamId,
        },
    };
    const folio = await adnFetch("/v1/folios/" + folderId, {
        token: token,
        options: {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
        },
    });
    return folio;
};

export const adnDeleteFolio = async (token: string, folioId: string) => {
    const body = {
        id: folioId,
        objectState: "INACTIVE",
    };
    const options: AdnFetchOptions = {
        token: token,
        options: {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        },
    };

    const folio = await adnFetch("/v1/folios/" + folioId, options);
    return folio;
};

export const adnFolio = async (token: string, folderId: string) => {
    return await adnFetch("/v1/folios/" + folderId, { token: token });
};
