import { ChangeEvent, useContext, useEffect, useState } from "react";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { adnFolios } from "../../functions/adnuntius/folios";
import { BiFolder } from "react-icons/bi";
import { FolioCreator } from "./FolioCreator";

export const FolioPicker = (props: any) => {
    // const [advertisers, setAdvertisers] = useState<any>(false);
    // const [filteredAdvertisers, setFilteredAdvertisers] = useState<any>([]);
    const [filter, setFilter] = useState("");
    const [folios, setFolios] = useState<any[] | false>(false);
    const [filteredFolios, setFilteredFolios] = useState<any[] | false>(false);
    const [view, setView] = useState<"search" | "create">("search");
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
        if (folios && folios.length !== 0) {
            const filtered = folios.filter((f: any) =>
                f.name.toLowerCase().includes(e.currentTarget.value.toLowerCase())
            );
            setFilteredFolios(filtered);
        }
    };

    useEffect(() => {
        const fetchFolios = async () => {
            const r = await adnFolios(globalState.get.token(), props.advertiser.id);
            if (r?.code === "error.unauthorised") {
                globalState.set.token("");
            }
            setFolios(r.results);
            setFilteredFolios(r.results);
        };

        if (props.advertiser.id) {
            fetchFolios();
        }
    }, [props.advertiser, globalState.get, globalState.set]);
    const title = props.folio ? (
        <>
            <span className="bold">
                <BiFolder />
            </span>
            <span>{props.folio.name}</span>
        </>
    ) : (
        <>
            <span>Select a folio</span>
            {props.open && (
                <span
                    className="new-btn button"
                    onClick={() => {
                        setFilter("");
                        setView("create");
                    }}>
                    new
                </span>
            )}
        </>
    );
    return (
        <>
            {view === "search" && (
                <div className={`folder-picker ${props.open ? "open" : ""}`}>
                    {!props.open && (
                        <h2
                            onClick={() => {
                                props.setFolio(false);
                                props.setCreativeSet(false);
                            }}
                            className="clickable">
                            {title}
                        </h2>
                    )}
                    {props.open && <h2 className="uncentered">{title}</h2>}
                    <input
                        type="text"
                        name="advertisers-filter"
                        id="advertisers-filter"
                        placeholder="Search folio"
                        onChange={onSearch}
                        value={filter}
                        tabIndex={props.open ? 0 : -1}
                    />
                    {filteredFolios && (
                        <ul>
                            {filteredFolios.map((f: any, i: number) => (
                                <li key={i} onClick={() => props.setFolio(f)} tabIndex={0}>
                                    <BiFolder /> {f.name}
                                </li>
                            ))}
                            {filteredFolios.length === 0 && (
                                <li tabIndex={1} onClick={() => setView("create")}>
                                    Create <span style={{ fontStyle: "italic" }}>{filter}?</span>
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            )}
            {view === "create" && (
                <FolioCreator
                    name={filter}
                    setView={() => setView("search")}
                    setFolio={(folio: any) => props.setFolio(folio)}
                    clearFolios={() => setFolios(false)}
                    advertiser={props.advertiser}
                />
            )}
        </>
    );
};
