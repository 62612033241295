import { ChangeEvent, useContext, useEffect, useState } from "react";
import { adnAdvertisers } from "../../functions/adnuntius/advertisers";
import { AdvertiserCreator } from "./AdvertiserCreator";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { BsPersonVcard } from "react-icons/bs";

export const AdvertiserPicker = (props: any) => {
    const [advertisers, setAdvertisers] = useState<any>(false);
    const [filteredAdvertisers, setFilteredAdvertisers] = useState<any>([]);
    const [filter, setFilter] = useState("");
    const [view, setView] = useState<"search" | "create">("search");
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const fetchAdvertisers = async () => {
        const teams = userTeams();
        const advs = await adnAdvertisers(globalState.get.token(), teams);
        if (!advs?.results) {
            globalState.set.token("");
        }
        setAdvertisers(advs);
        setFilteredAdvertisers(advs.results);
    };

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
        const filtered = advertisers.results.filter((a: any) =>
            a.name.toLowerCase().includes(e.currentTarget.value.toLowerCase())
        );
        setFilteredAdvertisers(filtered);
    };

    const userTeams = () => {
        const teamNames: string[] = [];
        props.user.user.userRoles.rolesByNetwork.forEach((rbn: any) => {
            if (rbn.network.id !== "adnuntius") {
                return false;
            }
            rbn.roles.forEach((role: any) => {
                role?.team && teamNames.push(role.team.name);
            });
        });
        return teamNames;
    };
    useEffect(() => {
        if (!advertisers) {
            fetchAdvertisers();
        }
    }, [advertisers]);
    const title = props.advertiser ? (
        <>
            <span className="bold">
                <BsPersonVcard />
            </span>
            <span>{props.advertiser.name}</span>
        </>
    ) : (
        <>
            <span>Select an advertiser</span>
            <span
                className="new-btn button"
                onClick={() => {
                    setFilter("");
                    setView("create");
                }}>
                new
            </span>
        </>
    );
    return (
        <>
            {view === "search" && (
                <div className={`advertiser-picker ${props.open && "open"}`}>
                    {!props.open && (
                        <h2
                            onClick={() => {
                                props.setAdvertiser(false);
                                props.setCreativeSet(false);
                            }}
                            className="clickable">
                            {title}
                        </h2>
                    )}
                    {props.open && <h2 className="uncentered">{title}</h2>}
                    <input
                        type="text"
                        name="advertisers-filter"
                        id="advertisers-filter"
                        placeholder="Search advertiser"
                        onChange={onSearch}
                        value={filter}
                        tabIndex={props.open ? 0 : -1}
                    />
                    {filteredAdvertisers && (
                        <ul>
                            {filteredAdvertisers.map((a: any, i: number) => (
                                <li key={i} onClick={() => props.setAdvertiser(a)} tabIndex={0}>
                                    <BsPersonVcard /> {a.name}
                                </li>
                            ))}
                            {filteredAdvertisers.length === 0 && (
                                <li tabIndex={1} onClick={() => setView("create")}>
                                    Create <span style={{ fontStyle: "italic" }}>{filter}?</span>
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            )}
            {view === "create" && (
                <AdvertiserCreator
                    name={filter}
                    setView={() => setView("search")}
                    setAdvertiser={(adv: any) => props.setAdvertiser(adv)}
                    clearAdvertisers={() => setAdvertisers(false)}
                />
            )}
        </>
    );
};
