import { baseUrl } from "./baseUrl";
import { getEnvironment } from "../getEnvironment";
import axios, { AxiosRequestConfig } from "axios";
export interface AdnFetchOptions {
    urlParams?: { [key: string]: string };
    options?: {
        method: "GET" | "POST";
        headers?: { [key: string]: string };
        body?: any;
    };
    token?: string;
}
export const adnFetch = async (endpoint: string, options: AdnFetchOptions) => {
    const _headers = options?.token ? { authorization: "Bearer " + options.token } : false;
    const _options = {
        method: "GET",
        ...options?.options,
    };
    if (_headers) {
        _options["headers"] = _headers;
    }
    if (options.options?.headers && Object.keys(options.options.headers).length !== 0) {
        _options.headers = { ..._headers, ...options.options.headers };
    }

    const params = new URLSearchParams("");
    if (options.hasOwnProperty("urlParams")) {
        Object.keys(options.urlParams!).forEach((name) => {
            params.append(name, options.urlParams![name]);
        });
    }
    // const href = document.location.href;
    // console.log(href);
    // let context = "";
    // if (href.includes("dev-studio.adnunti.us") || href.includes("localhost:")) {
    //     console.log("Environment: DEV");
    //     context = "2ac7c";
    // } else {
    //     context = "b7462";
    // }
    // if (href.includes("?env=prod")) {
    //     context = "b7462";
    // }
    const env = getEnvironment();
    if ((options.urlParams && !options.urlParams.context) || !options.urlParams) {
        const context = env === "dev" ? "2ac7c" : "b7462";
        params.append("context", context);
    }
    let url = `${baseUrl}${endpoint}`;

    if (params.toString()) {
        url += `?${params.toString()}`;
    }
    const r = await fetch(url, _options);
    const json = await r.json();
    return json;
};

export const adnAxiosPost = async (endpoint: string, options: AdnFetchOptions, uploadProgress?: any) => {
    const _headers = options?.token ? { authorization: "Bearer " + options.token } : false;

    const params = new URLSearchParams("");
    if (options.hasOwnProperty("urlParams")) {
        Object.keys(options.urlParams!).forEach((name) => {
            params.append(name, options.urlParams![name]);
        });
    }
    const env = getEnvironment();
    if ((options.urlParams && !options.urlParams.context) || !options.urlParams) {
        const context = env === "dev" ? "2ac7c" : "b7462";
        params.append("context", context);
    }

    const axiosOptions: AxiosRequestConfig<any> = {
        method: options.options?.method || "get",
        url: `${baseUrl}${endpoint}`,
    };
    if (_headers) axiosOptions.headers = _headers;
    if (params.toString()) axiosOptions.url += `?${params.toString()}`;
    if (options.options?.hasOwnProperty("body")) axiosOptions.data = options.options.body;
    if (uploadProgress) axiosOptions.onUploadProgress = uploadProgress;

    const x = await axios(axiosOptions);
    // console.log(x.data);
    return x.data;
};
