import { useLocation } from "react-router-dom";
import "../css/help.css";
import { useEffect } from "react";
export const Help = (props: any) => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            document.querySelectorAll("h3").forEach((node) => {
                node.classList.remove("active");
            });
            const activeElement = document.getElementById(location.hash.replace("#", ""));
            activeElement?.classList.add("active");
            activeElement?.scrollIntoView();
        }
    }, [location]);
    return (
        <div className="help-container">
            <div className="page">
                <h3 id="folio">Folio</h3>
                <div className="details">
                    <p>
                        A <span className="bold">folio</span> acts as a folder for creative sets. An advertiser can
                        contain several folios, each containing several creative sets.
                    </p>
                </div>
                <div className="spacer"></div>
                <h3 id="creativeset">Creative set</h3>
                <div className="details">
                    <p>
                        A <span className="bold">creative set</span> is a collection of creatives. When serving any
                        creative within a creative set, all statistics such as impressions, clicks and viewabilty are
                        aggregated to the creative set.
                    </p>
                </div>
                <div className="spacer"></div>
                <h3 id="creative">Creative</h3>
                <div className="details">
                    <p>
                        A <span className="bold">creative </span> is a single ad. In Studio, a creative is a single
                        video. Creatives belong to a creative set, and any creative set can contain multiple creatives.
                    </p>
                    <p>
                        Creatives are what will be rendered on the page, redirecting to a click URL on click. An
                        impression tracking URL is optional, allowing an external ad server to recieve impression
                        information.
                    </p>
                </div>
                <div className="spacer"></div>
            </div>
        </div>
    );
};
