import { useState } from "react";
import { FaCircleCheck, FaRegCircle } from "react-icons/fa6";
import { IoMdCheckmarkCircle, IoIosRadioButtonOff } from "react-icons/io";
import { TbSquare, TbSquareCheckFilled } from "react-icons/tb";

export const Item = (props: {
    itemClassName?: string;
    value?: any;
    valuesArr?: string[];
    checkable?: boolean;
    handleCheck?: any;
    handleUnCheck?: any;
    onClick?: any;
    checked?: boolean;
}) => {
    const [checked, setChecked] = useState<boolean>(false);
    const handleChecked = () => {
        if (checked) {
            setChecked(false);
            props.handleUnCheck && props.handleUnCheck();
        } else {
            setChecked(true);
            props.handleCheck && props.handleCheck();
        }
    };
    return (
        <div className={props.itemClassName ? `${props.itemClassName} item` : `item`}>
            {props.checkable && (
                <div className={`item-check ${props.checked ? "checked" : ""}`} onClick={handleChecked}>
                    {props.checked ? <TbSquareCheckFilled /> : <TbSquare />}
                </div>
            )}
            <div className="clickable" onClick={props.onClick}>
                {props.value && props.value}
                {props.valuesArr && props.valuesArr.map((v, i) => <span key={i}>{v}</span>)}
            </div>
        </div>
    );
};
