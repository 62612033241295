import { adnAxiosPost } from "./fetch";

export const adnAsset = async (token: string, assetId: string, uploadProgress?: any) => {
    // const lineItem = await adnFetch("/v1/assets/" + assetId, {
    const axiosOptions = {
        token: token,
        urlParams: {
            format: "json",
        },
    };
    const lineItem = await adnAxiosPost("/v1/assets/" + assetId, axiosOptions, uploadProgress);
    return lineItem;
};
