import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { adnPreview } from "../../functions/adnuntius/preview";
import { decode } from "js-base64";

import "../../css/preview.css";
import { getEnvironment } from "../../functions/getEnvironment";

export type PreviewPayload = {
    creativeId: string;
    width: number;
    height: number;
    advertiserName: string;
};

export const Preview = (props: any) => {
    const [preview, setPreview] = useState<any>(null);
    const [fetchingPreview, setFetchingPreview] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [dimensions, setDimensions] = useState<[number, number]>([0, 0]);
    const [sizeWarning, setSizeWarning] = useState<boolean>(false);
    const [advertiserName, setAdvertiserName] = useState<string>("");
    const [payload, setPayload] = useState<PreviewPayload>();
    const [environment, setEnvironment] = useState<"dev" | "prod" | null>(null);
    const [network, setNetwork] = useState<string>("");
    const params = useParams();

    useEffect(() => {
        const getPreview = async (id: string) => {
            setFetchingPreview(true);
            const previewResponse = await adnPreview(network, id);
            setPreview(previewResponse);
        };

        const handleDimensions = (width: number, height: number) => {
            const [wWidth, wHeight] = [window.innerWidth, window.innerHeight];
            const [maxWidth, maxHeight] = [wWidth * 0.9, wHeight * 0.8];
            const ratio = width / height;
            if (width > maxWidth || height > maxHeight) {
                setSizeWarning(true);
                if (width > height) {
                    setDimensions([maxWidth, height * ratio]);
                } else {
                    setDimensions([width * ratio, maxHeight]);
                }
            } else {
                setDimensions([width, height]);
            }
        };

        if (params.previewPayload && !payload) {
            const payload = JSON.parse(decode(params.previewPayload)) as PreviewPayload;
            setPayload(payload);
        }
        if (payload && !preview && !fetchingPreview && network !== "") {
            const creativeId = payload.creativeId;
            const width = payload.width;
            const height = payload.height;
            if (!preview && !fetchingPreview) {
                if (creativeId) {
                    getPreview(creativeId);
                    setAdvertiserName(payload.advertiserName);
                    handleDimensions(width, height);
                }
            }
        }
    }, [
        preview,
        fetchingPreview,
        params.creativeId,
        searchParams,
        params.previewPayload,
        payload,
        network,
        environment,
    ]);

    useEffect(() => {
        const env = getEnvironment();
        setEnvironment(env);
    }, []);

    useEffect(() => {
        if (environment === "dev") {
            setNetwork("2ac7c");
        } else if (environment === "prod") {
            setNetwork("b7462");
        }
    }, [environment]);

    return (
        <>
            <div id="preview">
                <div className="preview-modal">
                    <div className="row center">
                        <h2>
                            <span className="advertiser">{advertiserName} </span>ad preview
                        </h2>
                    </div>
                    {sizeWarning && payload && (
                        <div className="row center warning">
                            <p className="size-warning">
                                This creative is too large to display fully here.
                                <br />
                                <a
                                    href={`https://delivery.adnuntius.com/preview?context=${network}&creativeId=${payload?.creativeId}`}
                                    target="_blank"
                                    rel="noreferrer">
                                    open on a blank page
                                </a>
                            </p>
                        </div>
                    )}
                    <div className="row center" id="preview-video">
                        {preview && (
                            <iframe
                                id="video"
                                srcDoc={preview}
                                height={dimensions[1]}
                                width={dimensions[0]}
                                title="Ad preview"
                                className="ad-preview"></iframe>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
