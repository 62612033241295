import { AdnuntiusUser } from "../components/types/AdnuntiusUser";

export const hasGoogleAccess = (user: AdnuntiusUser): boolean => {
    if (user.user.username === "studiodemo@adnuntius.com") return true;
    const allowedTeamIds = [
        "nyk7jyc1p3g2czy8", // TRY
        "jyq589x2kdysqcpj", // Re Media AS
    ];
    let hasAllowedTeam = false;
    user.teams.forEach((team) => {
        if (allowedTeamIds.includes(team.id) && !hasAllowedTeam) {
            hasAllowedTeam = true;
        }
    });
    return hasAllowedTeam;
};
