import { CreateAdvertiser } from "./CreateAdvertiser";
import { CreateCreativeSet } from "./CreateCreativeSet";
import { CreateFolio } from "./CreateFolio";

type TypeName = "folio" | "advertiser" | "creativeSet";

interface CreateProps {
    visible: boolean;
    setVisible: any;
    advertiserId?: string;
    type: TypeName;
    onCreateFolio?: any;
    onCreateAdvertiser?: any;
    onCreateCreativeSet?: any;
    user?: any;
    folioId?: string;
}

export const Create = (props: CreateProps) => {
    const handleCloseClick = (e: any) => {
        if (e.target.classList.contains("manage-create-container")) {
            props.setVisible();
        }
    };

    const typeConversion: { [key: string]: string } = {
        folio: "folio",
        advertiser: "advertiser",
        creativeSet: "creative set",
    };

    return (
        <>
            {props.visible && (
                <div className="manage-create-container" onClick={handleCloseClick}>
                    <div className="manage-create">
                        <h2>
                            <span>Create {typeConversion[props.type]} </span>
                        </h2>
                        {props.type === "folio" && props.advertiserId && (
                            <CreateFolio
                                user={props.user}
                                advertiserId={props.advertiserId}
                                onCreateFolio={props.onCreateFolio}
                                setVisible={props.setVisible}
                            />
                        )}
                        {props.type === "advertiser" && (
                            <CreateAdvertiser
                                user={props.user}
                                onCreateAdvertiser={props.onCreateAdvertiser}
                                setVisible={props.setVisible}
                            />
                        )}
                        {props.type === "creativeSet" && props.folioId && (
                            <CreateCreativeSet
                                user={props.user}
                                onCreateCreativeSet={props.onCreateCreativeSet}
                                setVisible={props.setVisible}
                                folioId={props.folioId}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
