import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { SearchTypes, adnSearch } from "../functions/adnuntius/search";
import { useToken } from "../contexts/useToken";
import { useNavigate } from "react-router-dom";
import "../css/search.css";

type SearchOption = {
    value: string;
    label: string;
    type: string;
};

export const OmniSearch = (props: {
    types?: SearchTypes[];
    onSelect: any;
    autoFocus?: boolean;
    placeholder?: string;
}) => {
    const [options, setOptions] = useState<SearchOption[]>([]);
    const [query, setQuery] = useState<string>("");
    const token = useContext(useToken);
    const navigate = useNavigate();

    const NoOptionsComponent = (props: any) => <p>Type to search</p>;

    const formatOption = (data: SearchOption) => {
        return (
            <>
                <span className={`badge ${data.type}`}>{data.type}</span>
                <span className="label">{data.label}</span>
            </>
        );
    };

    const classNames = {
        option: (state: any) => {
            let c = "search-item";
            c = state.isFocused ? "search-item-focused" : c;
            c = state.isSelected ? "search-item-selected" : c;
            return c;
        },
    };

    const handleSearchChange = (input: string) => {
        setQuery(input);
    };

    const convertApiResponse = (response: any) => {
        const newOptions: SearchOption[] = response.searchResults.map((d: any) => {
            return {
                value: d.id,
                label: d.name,
                type: d.type,
            };
        });
        setOptions(newOptions);
    };

    useEffect(() => {
        const search = async (query: string) => {
            const results = await adnSearch(token, query, props.types);
            if (results.searchResults.length !== 0) {
                convertApiResponse(results);
            }
        };

        if (query !== "") {
            const debounce = setTimeout(() => {
                search(query);
            }, 1000);
            return () => {
                clearTimeout(debounce);
            };
        } else if (query === "") {
            setOptions([]);
        }
    }, [props.types, query, token]);
    return (
        <Select
            className="omni-search"
            options={options}
            onInputChange={handleSearchChange}
            formatOptionLabel={formatOption}
            onChange={props.onSelect}
            placeholder={props.placeholder || "Search..."}
            noOptionsMessage={NoOptionsComponent}
            classNames={classNames}
            autoFocus={props.autoFocus}
        />
    );
};
