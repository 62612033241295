import { AdnItemWithId } from "./Toolbox";

export const RenameList = (props: { items: AdnItemWithId[]; editedItems: AdnItemWithId[]; onChange: any }) => {
    return (
        <>
            {props.items.map((item, i) => (
                <li key={i}>
                    <label htmlFor={`item-${i}`}>{item.name}</label>
                    <input
                        type="text"
                        name=""
                        id={`item-${i}`}
                        value={props.editedItems[i].name}
                        onChange={(e) => props.onChange(i, e.target.value)}
                    />
                </li>
            ))}
        </>
    );
};
