import { useEffect, useState } from "react";
import { Quartile, Quartiles } from "./graphs/Quartiles";
import { Completion, CompletionData } from "./graphs/Completion";

export const Graphs = (props: { stats: any }) => {
    const [quartiles, setQuartiles] = useState<Quartile[] | null>(null);
    const [completion, setCompletion] = useState<CompletionData[] | null>(null);

    useEffect(() => {
        if (!quartiles) {
            if (props.stats.totals.hasOwnProperty("video-quartile-1")) {
                const qStatsArray: Quartile[] = [];
                for (let i = 1; i <= 4; i++) {
                    qStatsArray.push({
                        name: `Q${i}`,
                        events: props.stats.totals?.[`video-quartile-${i}`]?.count || 0,
                    });
                }
                setQuartiles(qStatsArray);
            }
        }
        if (!completion && quartiles) {
            let totalEvents = 0;
            let q = 0;
            quartiles.forEach((c: Quartile) => {
                totalEvents += c.events;
                let v = 0;
                switch (c.name) {
                    case "Q1":
                        v = 25;
                        break;
                    case "Q2":
                        v = 50;
                        break;
                    case "Q3":
                        v = 75;
                        break;
                    case "Q4":
                        v = 100;
                }
                q += v * c.events;
            });
            const average = q / totalEvents;
            setCompletion([
                { name: "average", value: average },
                { name: "total", value: 100 - average },
            ]);
        }
    }, [quartiles, completion, props.stats.totals]);

    return (
        <div className={quartiles ? "graphs" : ""}>
            {quartiles && <Quartiles data={quartiles} />}
            {completion && <Completion data={completion} />}
        </div>
    );
};
