import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { adnPostAdvertiser } from "../../functions/adnuntius/advertiser";
import { CgCheckO, CgSpinnerTwo } from "react-icons/cg";
import { useUser } from "../../contexts/useUser";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";

export const AdvertiserCreator = (props: { name: string; setView: any; setAdvertiser: any; clearAdvertisers: any }) => {
    const [advertiserName, setAdvertiserName] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [waiting, setWaiting] = useState<boolean>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const user = useContext(useUser);

    const handleCreate = () => {
        const createAdvertiser = async () => {
            setWaiting(true);
            const createdAdvertiser = await adnPostAdvertiser(
                globalState.get.token(),
                advertiserName,
                uuidv4(),
                user?.teams[0].id
            );
            if (createdAdvertiser.id) {
                setTimeout(() => setSuccess(true), 1000);
                setTimeout(() => {
                    props.clearAdvertisers();
                    props.setAdvertiser(createdAdvertiser);
                    props.setView();
                }, 1500);
            }
        };

        createAdvertiser();
    };

    const handleNameChange = (e: any) => {
        setAdvertiserName(e.target.value);
    };
    useEffect(() => {
        if (props.name) {
            setAdvertiserName(props.name);
        }
    }, [props.name]);

    const Popup = (props: { success: boolean }) => {
        return (
            <div className="create-popup">
                {!success && (
                    <>
                        <CgSpinnerTwo className="icon spin" size="10rem" />
                        <p>Creating advertiser</p>
                    </>
                )}
                {success && (
                    <>
                        <CgCheckO size="10rem" className="icon success" />
                        <p>Advertiser created</p>
                    </>
                )}
            </div>
        );
    };

    return (
        <div className="advertiser-creator">
            {waiting && <Popup success={success} />}
            <h2>Create new advertiser</h2>
            <label htmlFor="advertiser-name">New advertiser name</label>
            <input type="text" name="" id="advertiser-name" value={advertiserName} onChange={handleNameChange} />
            <div className="row">
                <input type="button" className="cancel button" onClick={props.setView} value="Cancel" />
                <input type="button" className="create button" value="Create" onClick={handleCreate} />
            </div>
        </div>
    );
};
