import { AdnFetchOptions, adnFetch } from "./fetch";

export const adnAdvertiser = async (token: string, id: string) => {
    const advertisers = await adnFetch("/v1/advertisers/" + id, { token: token });
    return advertisers;
};

export const adnPostAdvertiser = async (token: string, name: string, advertiserId: string, teamId: string) => {
    const body = {
        name: name,
        ownerTeam: {
            id: teamId,
        },
    };
    const opts: AdnFetchOptions = {
        token: token,
        options: {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" },
        },
    };
    const advertiser = adnFetch("/v1/advertisers/" + advertiserId, opts);
    return advertiser;
};
