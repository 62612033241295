import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { CgCheckO, CgSpinnerTwo } from "react-icons/cg";
import { useUser } from "../../contexts/useUser";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { adnPostFolio } from "../../functions/adnuntius/folios";

export const FolioCreator = (props: {
    name: string;
    setView: any;
    setFolio: any;
    clearFolios: any;
    advertiser: any;
}) => {
    const [folderName, setFolderName] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [waiting, setWaiting] = useState<boolean>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const user = useContext(useUser);

    const handleCreate = () => {
        const createFolder = async () => {
            setWaiting(true);
            const folder = await adnPostFolio(globalState.get.token(), uuidv4(), {
                advertiserId: props.advertiser.id,
                name: folderName,
                teamId: user.teams[0].id,
            });
            if (folder.id) {
                setTimeout(() => setSuccess(true), 1000);
                setTimeout(() => {
                    props.clearFolios();
                    props.setFolio(folder);
                    props.setView();
                }, 1500);
            }
        };

        createFolder();
    };

    const handleNameChange = (e: any) => {
        setFolderName(e.target.value);
    };
    useEffect(() => {
        if (props.name) {
            setFolderName(props.name);
        }
    }, [props.name]);

    const Popup = (props: { success: boolean }) => {
        return (
            <div className="create-popup">
                {!success && (
                    <>
                        <CgSpinnerTwo className="icon spin" size="10rem" />
                        <p>Creating folio</p>
                    </>
                )}
                {success && (
                    <>
                        <CgCheckO size="10rem" className="icon success" />
                        <p>Folio created</p>
                    </>
                )}
            </div>
        );
    };

    return (
        <div className="folder-creator">
            {waiting && <Popup success={success} />}
            <h2>New folio</h2>
            <label htmlFor="folder-name">New folio name</label>
            <input type="text" name="" id="folder-name" value={folderName} onChange={handleNameChange} />
            <div className="row">
                <input
                    type="button"
                    className="cancel button"
                    onClick={() => {
                        props.setView();
                        props.clearFolios();
                    }}
                    value="Cancel"
                />
                <input type="button" className="create button" value="Create" onClick={handleCreate} />
            </div>
        </div>
    );
};
