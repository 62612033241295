const _sort = {
    createTime: {
        ascending: (a: any, b: any) => {
            if (a.createTime < b.createTime) {
                return -1;
            }
            if (a.createTime > b.createTime) {
                return 1;
            }
            return 0;
        },
        descending: (a: any, b: any) => {
            if (a.createTime < b.createTime) {
                return 1;
            }
            if (a.createTime > b.createTime) {
                return -1;
            }
            return 0;
        },
    },
    name: {
        ascending: (a: any, b: any) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        },
        descending: (a: any, b: any) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return 1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return -1;
            }
            return 0;
        },
    },
};

export const by = (key: string) => {
    return {
        direction: (direction: "ascending" | "descending") => {
            return (a: any, b: any) => {
                if (a[key].toLowerCase() < b[key].toLowerCase()) {
                    return direction === "ascending" ? -1 : 1;
                }
                if (a[key].toLowerCase() > b[key].toLowerCase()) {
                    return direction === "ascending" ? 1 : -1;
                }
                return 0;
            };
        },
    };
};
