import { getEnvironment } from "./getEnvironment";

export const generateHtmlFile = (
    width: string,
    height: string,
    divId: string,
    creativeSetId: string,
    url: string,
    limited: boolean = false
) => {
    const env = getEnvironment();
    const limitedString = limited ? `env: "limited"` : ``;
    const oldStr = `
<html>
    <head>
        <meta name="ad.size" content="width=${width},height=${height}" />
    </head>
    <body style="margin:0px">
        <script>
            var clickTag = "";
        </script>
        <div id="adn-${divId}"></div>
        <script src="https://cdn.adnuntius.com/adn.js" async></script>

        <script>
            window.adn = window.adn || {};
            adn.calls = adn.calls || [];
            adn.calls.push(function () {
                adn.request({
                    creativeSetId: "${creativeSetId}",
                    targetId: "adn-${divId}",
                    targeting: true,
                    network: "${env === "dev" ? "2ac7c" : "b7462"}",
                    dimensions: [[${width}, ${height}]],
                    clickTrackingUrl: clickTag,
                    ${limitedString}
                });
            });
        </script>
    </body>
</html>
    `;
    const newStr = `
    <html>

<head>
	<meta name="ad.size" content="width=${width},height=${height}" />
</head>

<body style="margin:0px">
	<div id="${divId}"></div>
	<script src="https://cdn.adnuntius.com/adn.js" async></script>

	<script>
		var clickTag = "";
	</script>

	<script>
		window.adn = window.adn || {};
		adn.calls = adn.calls || [];
		var loadad = function () {
			adn.calls.push(function () {
				adn.request({
					creativeSetId: "${creativeSetId}",
					targetId: "${divId}",
					targeting: true,
					network: "${env === "dev" ? "2ac7c" : "b7462"}",
					dimensions: [[${width}, ${height}]],
					clickTrackingUrl: clickTag,
                    ${limitedString}
				});
			});
		}

		function inIframe() {
			try {
				return window.self !== window.top;
			} catch (e) {
				return true;
			}
		}

		if (inIframe()) {
			window.addEventListener('message', function (event) {
				loadad()
			});
		} else {
			loadad()
		}

	</script>
</body>

</html>`;
    return newStr;
};
