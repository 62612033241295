import { adnFetch } from "./fetch";

interface CampaignsOptions {
    token: string;
    urlParams?: {
        [key: string]: string;
    };
}

export const adnCreativeSets = async (
    token: string,
    opts: {
        context?: string;
        advertiserId?: string;
        page?: number;
        pageSize?: number;
        filter?: boolean;
        folioId?: string;
    }
) => {
    const options: CampaignsOptions = {
        urlParams: {
            pageSize: opts.pageSize ? opts.pageSize.toString() : "25",
            page: opts.page ? opts.page.toString() : "1",
            concise: "true",
            includeInactive: "false",
            includeHidden: "false",
        },
        token: token,
    };
    if (opts.advertiserId) {
        options.urlParams!.advertiser = opts.advertiserId;
    } else if (opts.folioId) {
        options.urlParams!.folio = opts.folioId;
    }
    if (opts.context) {
        options.urlParams!.context = opts.context;
    }
    const filter = opts.filter ? opts.filter : false;
    const campaigns = await adnFetch("/v1/creativesets", options);
    if (filter && campaigns?.results) {
        const filteredResults = campaigns.results.filter((c: any) => {
            return c.advertiser.id === opts.advertiserId;
        });
        campaigns.results = filteredResults;
    }
    return campaigns;
};
