import { AdnItemWithId } from "./Toolbox";

export const DeleteList = (props: { items: AdnItemWithId[] }) => {
    return (
        <>
            {props.items.map((item, i) => (
                <li key={i}>
                    <span className="item-name">{item.name}</span>
                </li>
            ))}
        </>
    );
};
