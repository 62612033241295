import { useContext, useEffect, useState } from "react";
import { AdnuntiusCreative } from "../types/AdnuntiusCreative";
import { useAssets } from "../../hooks/useAssets";
import { AdnuntiusAsset } from "../types/AdnuntiusAsset";
import { VideoStreamingOptions, adnPostCreative } from "../../functions/adnuntius/creative";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { IoEyeOutline } from "react-icons/io5";
import { TbVideoPlus, TbPhoto } from "react-icons/tb";
import { Fallback } from "../videoEditor/fallback/Fallback";

type CreativeProps = {
    creative: AdnuntiusCreative;
    small?: boolean;
    onClick?: any;
    onChange: (arg0: ChangedCreative) => any;
    onSave?: any;
};

type LayoutOption = {
    layoutValue: string;
    label: string;
};

export type ChangedCreative = {
    id: string;
    name: string;
    clickUrl: string;
    impUrl: string;
    onEnd: string;
};

export const Creative = (props: CreativeProps) => {
    const [clickUrl, setClickUrl] = useState<string>(props.creative.constraintsToUrls.destination);
    const [name, setName] = useState<string>(props.creative.name);
    const [impUrl, setImpUrl] = useState<string>(props.creative.impressionTrackingUrls[0]);
    const [onEnd, setOnEnd] = useState<string>(props.creative.layoutParameters.onEnd);
    const [fallback, setFallback] = useState<number | undefined>(undefined);
    const [hasChanged, setHasChanged] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [showFallbackEditor, setShowFallbackEditor] = useState<boolean>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const getAssetById = (id: string): AdnuntiusAsset | false => {
        let returnAsset: AdnuntiusAsset | false = false;
        if (assets) {
            assets.forEach((asset) => {
                if (asset.id === id) {
                    returnAsset = asset;
                }
            });
        }
        return returnAsset;
    };

    const c = props.creative;
    const assets = useAssets(props.creative.id);
    const asset = getAssetById(c.constraintsToAssets.video.id);
    const videoSrc = asset ? asset.cdnId : false;
    const previewImage = asset ? asset.transcode.preview.cdnId : false;

    const options: LayoutOption[] = [
        { layoutValue: "Loop forever", label: "Forever" },
        { layoutValue: "Loop 3x", label: "3x" },
        { layoutValue: "Stop", label: "No loop" },
    ];

    const handleChange = (e: any) => {
        if (e.target.id === "name") setName(e.target.value);
        if (e.target.id === "clickUrl") setClickUrl(e.target.value);
        if (e.target.id === "impUrl") setImpUrl(e.target.value);
        if (e.target.id === "onEnd") setOnEnd(e.target.value);
    };

    useEffect(() => {
        if (
            name !== c.name ||
            clickUrl !== c.constraintsToUrls.destination ||
            impUrl !== c.impressionTrackingUrls[0] ||
            onEnd !== c.layoutParameters.onEnd
        ) {
            setHasChanged(true);
            props.onChange({
                id: props.creative.id,
                name: name,
                clickUrl: clickUrl,
                impUrl: impUrl,
                onEnd: onEnd,
            });
        } else {
            setHasChanged(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickUrl, impUrl, name, onEnd]);

    return (
        <div className={`creative ${props.small ? "small" : ""}`}>
            <div className={`title ${hasChanged ? "changed" : ""}`} onClick={props.onClick}>
                <div className="small-preview">
                    {props.small && videoSrc && previewImage && <img src={previewImage} alt="Video preview" />}
                </div>
                <h4 className={`creative-name`}>
                    {name} {hasChanged ? "*" : ""}
                </h4>
                {props.small && (
                    <span className="creative-size">
                        ({c.width}x{c.height}px)
                    </span>
                )}
            </div>
            {!props.small && (
                <div className="row">
                    <div className={`creative-preview ${showFallbackEditor ? "wide" : ""}`}>
                        {videoSrc && previewImage && !showFallbackEditor && (
                            <>
                                {/* <video src={videoSrc}></video> */}
                                <img src={previewImage} alt="Video preview" />
                                <span className="creative-size">
                                    {c.width} x {c.height}
                                </span>
                                {/* <div className="row video-commands">
                                    <div className="button" onClick={() => setShowFallbackEditor(!showFallbackEditor)}>
                                        <TbPhoto />
                                    </div>
                                    <div className="button">
                                        <TbVideoPlus />
                                    </div>
                                </div> */}
                            </>
                        )}
                        {/* {videoSrc && showFallbackEditor && (
                            <Fallback
                                videoSrc={videoSrc}
                                videoElementId={`video-${c.id}`}
                                asPopup={false}
                                showTitle={false}
                                showSlider
                                clean
                                open={true}
                                cancelButton={false}
                                onClose={() => setShowFallbackEditor(false)}
                                onSetFallbackImage={(n) => {
                                    console.log("N:", n);
                                }}
                            />
                        )} */}
                    </div>
                    <div className="creative-details">
                        <div className="detail-item">
                            <label htmlFor="name">Name</label>
                            <input type="text" name="" id="name" value={name} onChange={handleChange} />
                        </div>
                        <div className="detail-item">
                            <label htmlFor="clickUrl">Click URL</label>
                            <input type="text" name="" id="clickUrl" value={clickUrl} onChange={handleChange} />
                        </div>
                        <div className="detail-item">
                            <label htmlFor="impUrl">Impression tracking URL</label>
                            <input type="text" name="" id="impUrl" value={impUrl} onChange={handleChange} />
                        </div>
                        <div className="detail-item start">
                            <label htmlFor="videoEnd">Loop video</label>
                            <select name="" id="onEnd" onChange={handleChange} value={c.layoutParameters.onEnd}>
                                {options.map((opt: LayoutOption, i: number) => (
                                    <option key={i} value={opt.layoutValue}>
                                        {opt.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
