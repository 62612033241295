import { ChangeEvent, FormEvent, useContext, useEffect, useState, useCallback } from "react";
import { adn2fa, adnAuthenticate, adnMasquerade } from "../functions/adnuntius/authenticate";
import adnUser from "../functions/adnuntius/user";
import { GlobalStateManager, useGlobalState } from "../contexts/useGlobalState";

type MasqueradeUser = {
    username: string;
    password: string;
    masqueradeUser: string;
    twoFactorAuthCode: string;
};

export const Login = (props: { setToken: any; updateProfile: any; user: any }) => {
    const [user, setUser] = useState<{ username: string; password: string }>({ username: "", password: "" });
    const [fetchingUser, setFetchingUser] = useState<boolean>(false);
    const [isMasquerade, setIsMasquerade] = useState<boolean>(false);
    const [masqueradeUser, setMasqueradeUSer] = useState<MasqueradeUser>({
        username: "",
        password: "",
        masqueradeUser: "",
        twoFactorAuthCode: "",
    });
    const [isSettingProfile, setIsSettingProfile] = useState<boolean>(false);
    const [loginFailedMessage, setLoginFailedMessage] = useState<string>("");
    const [is2fa, setis2fa] = useState<boolean>(false);
    const [twoFAKey, setTwoFAKey] = useState<string>("");
    const [twoFAToken, setTwoFAToken] = useState<string>("");
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const setUserProfile = useCallback(
        async (token: string) => {
            setFetchingUser(true);
            const userProfile = await adnUser(user.username, token);
            setIsSettingProfile(false);
            // If token isn't valid
            if (!userProfile?.user?.id) {
                localStorage.removeItem("token");
                return false;
            }
            props.setToken(token);
            globalState.set.token(token);
            props.updateProfile(userProfile);
            setFetchingUser(false);
        },
        [globalState.set, props, user.username]
    );

    useEffect(() => {
        if (!props.user) {
            const existingToken = localStorage.getItem("token");
            if (existingToken && !fetchingUser) {
                setUserProfile(existingToken);
            }
        }
    }, [props.user, setUserProfile]);

    const handleAuthenticate = async (e: FormEvent) => {
        e.preventDefault();
        setIsSettingProfile(true);
        let response;
        if (is2fa) {
            response = await adn2fa(twoFAToken, twoFAKey);
        } else if (!isMasquerade) {
            response = await adnAuthenticate(user);
        } else if (isMasquerade) {
            response = await adnMasquerade(masqueradeUser);
        }
        if (response?.scope === "TWO_FACTOR_AUTH") {
            setis2fa(true);
            setTwoFAToken(response.access_token);
            setIsSettingProfile(false);
        }
        if (response.access_token) {
            props.setToken(response.access_token);
            localStorage.setItem("token", response.access_token);
            setUserProfile(response.access_token);
        } else {
            setIsSettingProfile(false);
            setLoginFailedMessage(response.text);
        }
    };

    const handleUsernameInput = (e: ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, username: e.target.value });
        if (e.target.value === "adnuntius@adnuntius.com") {
            setIsMasquerade(true);
            setMasqueradeUSer({ ...masqueradeUser, masqueradeUser: e.target.value });
        }
    };
    const handlePasswordInput = (e: ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, password: e.target.value });
        isMasquerade && setMasqueradeUSer({ ...masqueradeUser, password: e.target.value });
    };

    const handleMasqueradingUser = (e: ChangeEvent<HTMLInputElement>) => {
        setMasqueradeUSer({ ...masqueradeUser, username: e.target.value });
    };
    const handle2FA = (e: ChangeEvent<HTMLInputElement>) => {
        setMasqueradeUSer({ ...masqueradeUser, twoFactorAuthCode: e.target.value });
    };

    const handle2faInput = (e: ChangeEvent<HTMLInputElement>) => {
        setTwoFAKey(e.target.value);
    };

    return (
        <>
            {!isSettingProfile && !is2fa && (
                <form onSubmit={handleAuthenticate}>
                    <label htmlFor="username">{isMasquerade ? "Masquerading as" : "Username"}</label>
                    <input
                        type="text"
                        name="username"
                        id="username"
                        autoComplete="username"
                        onChange={handleUsernameInput}
                    />
                    {isMasquerade && (
                        <>
                            <label htmlFor="masqUser">Username</label>
                            <input type="text" name="masqUser" id="masqUser" onChange={handleMasqueradingUser} />
                        </>
                    )}
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        autoComplete="password"
                        onChange={handlePasswordInput}
                    />
                    {isMasquerade && (
                        <>
                            <label htmlFor="2fa">2FA token</label>
                            <input type="text" name="2fa" id="2fa" onChange={handle2FA} />
                        </>
                    )}
                    <input type="submit" value="Log in" className="button" />
                    {loginFailedMessage !== "" && (
                        <>
                            <br />
                            <span className="login-failed">{loginFailedMessage}</span>
                        </>
                    )}
                </form>
            )}
            {is2fa && !isSettingProfile && (
                <form onSubmit={handleAuthenticate}>
                    <div className="2fa" style={{ marginTop: "2rem" }}>
                        <label htmlFor="2fa">2 Factor Authentication Key</label>
                        <input type="text" name="2fa" id="2fa" autoComplete="2fa" onChange={handle2faInput} />
                        <input type="submit" value="Log in" className="button" />
                    </div>
                </form>
            )}
            {isSettingProfile && (
                <>
                    <h4>Logging in</h4>
                </>
            )}
        </>
    );
};
