import { AdnFetchOptions, adnFetch } from "./fetch";
import { v4 as uuidv4 } from "uuid";

export const adnCopy = async (
    token: string,
    type: "folio" | "creativeSet",
    id: string,
    name: string,
    folioId?: string
) => {
    const newFolioId = folioId || uuidv4();
    let url: string;
    switch (type) {
        case "folio":
            url = "/v1/folios";
            break;
        case "creativeSet":
            url = "/v1/creativesets";
    }
    const fullUrl = `${url}/${id}/copy`;
    const postBody = {
        copyCreatives: "true",
        copyLineItems: "true",
        copyTargeting: "true",
        name: name,
        folioId: newFolioId,
        id: id,
    };
    let requestOptions: AdnFetchOptions = {
        token: token,
        options: {
            method: "POST",
            body: JSON.stringify(postBody),
            headers: {
                "Content-Type": "application/json",
            },
        },
        urlParams: {
            copyCreatives: "true",
            copyLineItems: "true",
            copyTargeting: "true",
            name: name,
            folioId: newFolioId,
            id: id,
        },
    };
    if (type === "folio") {
        requestOptions.urlParams!.folioId = newFolioId;
    }
    const r = await adnFetch(fullUrl, requestOptions);
    return r;
};
