import { useEffect, useState } from "react";
import "../../css/whatsnew.css";
import { news } from "./news";

export const WhatsNew = (props: { handleCloseNews: any }) => {
    const [filteredNews, setFilteredNews] = useState<{ [key: string]: any }>(news);
    const today = new Date().toISOString().split("T")[0];
    localStorage.setItem("lastRead", Object.keys(news).length.toString());
    const handleCoverClick = (e: any) => {
        props.handleCloseNews();
    };
    return (
        <>
            <div className="whatsnew-cover" onClick={handleCoverClick}></div>
            <div className="whatsnew">
                <div className="content">
                    <ul>
                        {Object.keys(filteredNews).map((date: string, i: number) => (
                            <li key={i}>
                                <h3>{date}</h3>
                                {filteredNews[date]}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="row end">
                    <div className="button" onClick={props.handleCloseNews}>
                        Close
                    </div>
                </div>
            </div>
        </>
    );
};
