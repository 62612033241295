import JSZip from "jszip";
import { generateHtmlFile } from "../../functions/generateHtmlFile";
import saveAs from "file-saver";
import Select from "react-select";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { log } from "console";
import { useEffect, useState } from "react";
import { Checkbox } from "../Checkbox";

export const DownloadZip = (props: { creativeSetId: string; creativeSetName: string; creatives: any }) => {
    const [url, setUrl] = useState<string>("");
    const [options, setOptions] = useState<{ label: string; value: string[] | string }[] | null>(null);
    const [sizeRange, setSizeRange] = useState<{ label: string; value: string[] | string } | null>(null);
    const [limited, setLimited] = useState<boolean>(false);

    const handleSaveZip = async () => {
        if (sizeRange?.value === "all") {
            handleSaveAllZip();
        } else {
            const zip = new JSZip();
            const randomHex = [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
            if (sizeRange) {
                const fileContents = generateHtmlFile(
                    sizeRange.value[0],
                    sizeRange.value[1],
                    randomHex,
                    props.creativeSetId,
                    "",
                    limited
                );
                zip.file("index.html", fileContents);
                zip.generateAsync({ type: "blob" }).then((blob) => {
                    saveAs(
                        blob,
                        `${props.creativeSetName.replaceAll(" ", "_")}_${sizeRange.value[0]}x${sizeRange.value[1]}`
                    );
                });
            }
        }
    };

    const handleSaveAllZip = async () => {
        const zipPromises: Promise<any>[] = [];
        options?.forEach((o) => {
            if (o.value !== "all") {
                zipPromises.push(createLabeledZipFilePromise(o.value[0], o.value[1]));
            }
        });
        const zips = await Promise.all(zipPromises!);
        const zip = new JSZip();
        zips.forEach((z) => {
            zip.file(z.label, z.file);
        });
        const x = await zip.generateAsync({ type: "blob" });
        saveAs(x, `${props.creativeSetName.replaceAll(" ", "_")}.zip`);
    };

    const createLabeledZipFilePromise = async (width: any, height: any) => {
        const randomHex = [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
        let zip = new JSZip();
        const fileContents = generateHtmlFile(width, height, randomHex, props.creativeSetId, url, limited);
        zip.file("index.html", fileContents);
        const zipFile = await zip.generateAsync({ type: "blob" });
        return { label: `${props.creativeSetName.replaceAll(" ", "_")}_${width}x${height}.zip`, file: zipFile };
    };

    const handleSelectSizeRange = (val: any) => {
        setSizeRange(val);
    };

    const handleUrlChange = (e: any) => {
        setUrl(e.currentTarget.value);
    };

    let clickUrls: string[] = [];
    props.creatives.forEach((c: any) => {
        !clickUrls.includes(c.constraintsToUrls.destination) && clickUrls.push(c.constraintsToUrls.destination);
    });
    if (!url && clickUrls[0]) {
        setUrl(clickUrls[0]);
    }

    const menuStyle = {
        menu: (style: any) => ({ ...style, zIndex: 100, color: "#00000088" }),
        control: (style: any) => ({ ...style, borderRadius: ".5rem" }),
    };
    const itemState = {
        option: (state: any) => {
            let c = "zip-item";
            c = state.isFocused ? "zip-item-focused" : c;
            c = state.isSelected ? "zip-item-selected" : c;
            return c;
        },
    };

    useEffect(() => {
        if (sizeRange === null) {
            let sizes = props.creatives.map((c: any) => {
                return `${c.width} x ${c.height}`;
            });
            let filteredSizes: any[] = [];
            let opts: { label: string; value: any[] | string }[] = [];
            sizes.forEach((s: string) => {
                if (!filteredSizes.includes(s)) {
                    filteredSizes.push(s);
                    opts.push({ label: s, value: s.split(" x ") });
                }
            });
            opts.push({ label: "Download all sizes", value: "all" });
            setOptions(opts);
            setSizeRange(opts[0]);
        }
    }, [props.creatives, sizeRange]);
    return (
        <div className="zip-container">
            {options && (
                <>
                    <label htmlFor="size-range">Size range</label>
                    <Select
                        id="size-range"
                        options={options}
                        value={sizeRange}
                        onChange={handleSelectSizeRange}
                        styles={menuStyle}
                        classNames={itemState}
                        className="select-tag"
                    />
                    {/* <label htmlFor="zip-click-url">Click URL</label> */}
                    {/* <input type="text" name="clickUrl" id="zip-click-url" onChange={handleUrlChange} value={url} /> */}
                    <div className="checkbox">
                        <Checkbox
                            checked={limited}
                            label="Serve in Google Ad Manager"
                            align="left"
                            labelAlsoChecks
                            onCheck={() => setLimited(!limited)}
                        />
                    </div>
                    <div className="button" id="download-button" onClick={handleSaveZip}>
                        <RiDownloadCloud2Line />
                        Download ZIP
                    </div>
                </>
            )}
        </div>
    );
};
