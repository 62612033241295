import { getEnvironment } from "../getEnvironment";
import { AdnFetchOptions, adnFetch } from "./fetch";
import { videoStreaming } from "./layouts/videoStreaming";

export const adnGetCreative = async (token: string, creativeId: string) => {
    const r = await adnFetch("/v1/creatives/" + creativeId, { token: token });
    return r;
};

interface Layout {
    height?: number;
    width?: number;
    url: string;
    impressionTrackingUrl?: string;
}

interface VideoStreamingLayout extends Layout {
    fullscreen?: "true" | "false";
    mute?: boolean;
    onEnd: "loop" | "loop3x" | "stop";
    video?: {
        id: string;
    };
}
export interface VideoStreamingOptions extends CreativeOptions {
    layout: VideoStreamingLayout;
}

export interface CreativeOptions {
    creativeSetId: string;
    creativeId: string;
    creativeName: string;

    token: string;
}

export const adnPostCreative = {
    videoStreaming: async (opts: VideoStreamingOptions) => {
        let onEnd;
        switch (opts.layout.onEnd) {
            case "loop":
                onEnd = "Loop forever";
                break;
            case "loop3x":
                onEnd = "Loop 3x";
                break;
            case "stop":
                onEnd = "Stop";
        }
        const body = {
            ...videoStreaming,
            name: opts.creativeName,
            creativeSet: {
                id: opts.creativeSetId,
            },
            width: opts.layout.width,
            height: opts.layout.height,
            layoutParameters: {
                destination: opts.layout.url,
                mute: opts.layout.mute ? "Yes" : "No",
                onEnd: onEnd,
                size: "100",
                fullscreen: opts.layout.fullscreen,
            },
        } as any;
        if (getEnvironment() === "prod") {
            body.layout.id = "video_v2";
        }
        if (opts?.layout?.video?.id) {
            body.layoutParameters = { ...body.layoutParameters, video: { id: opts.layout.video.id } };
        }
        if (opts?.layout.impressionTrackingUrl) {
            body.impressionTrackingUrls = [opts.layout.impressionTrackingUrl];
        }
        const options: AdnFetchOptions = {
            token: opts.token,
            options: {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            },
        };
        const r = await adnFetch(`/v1/creatives/${opts.creativeId}`, options);
        return r;
    },
};

export const adnCreatives = async (token: string, creativeSetId: string) => {
    const creatives = await adnFetch("/v1/creatives", {
        urlParams: {
            creativeSet: creativeSetId,
            pageSize: "999",
            includeActive: "true",
            includeInactive: "false",
            includeHidden: "false",
        },
        token: token,
    });
    return creatives;
};
