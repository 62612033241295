import { ChangeEvent, useContext, useEffect, useState } from "react";

import { Item } from "./Item";
import { adnAdvertiser } from "../../functions/adnuntius/advertiser";
import { adnFolios } from "../../functions/adnuntius/folios";
import { BiArrowBack, BiFolder } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
// import { TagPicker } from "./TagPicker";
import { Tooltip } from "react-tooltip";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa6";
import { BsPersonVcard } from "react-icons/bs";
import { Create } from "./Create/Create";
import { by } from "../../functions/sort";
import { Toolbox } from "./toolbox/Toolbox";
import { helpParser } from "../../functions/helpParser";

export const AdvertiserView = (props: { advertiserId: string; user: any }) => {
    const [campaigns, setCampaigns] = useState<any[]>([]);
    const [folios, setFolios] = useState<any[]>([]);
    const [fetchingFolios, setFetchingFolios] = useState<boolean>(false);
    const [filteredFolios, setFilteredFolios] = useState<any[]>([]);
    const [filter, setFilter] = useState<string>("");
    const [advertiser, setAdvertiser] = useState<any>(undefined);
    const [fetchingAdvertiser, setFetchingAdvertiser] = useState<boolean>(false);
    const [selectedFolios, setSelectedFolios] = useState<any[]>([]);
    const [filteredByDate, setFilteredByDate] = useState<false | "asc" | "desc">(false);
    const [filteredByName, setFilteredByName] = useState<false | "asc" | "desc">(false);
    const [currentFilter, setCurrentFilter] = useState<"name" | "date">("name");
    const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
    const [editActive, setEditActive] = useState<boolean>(false);
    const navigate = useNavigate();

    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const toggleIsCreateVisible = () => {
        setIsCreateVisible(!isCreateVisible);
    };

    const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
    };

    const handleBack = () => {
        navigate("/manage");
    };

    const handleCheck = (index: number) => {
        const newFolios = filteredFolios.map((cs: any, i: number) => {
            if (i === index) {
                return { ...cs, checked: true };
            } else {
                return cs;
            }
        });
        setFilteredFolios(newFolios);
        setSelectedFolios(newFolios.filter((f) => f.checked));
    };

    const handleUnCheck = (index: number) => {
        const newFolios = filteredFolios.map((cs: any, i: number) => {
            if (i === index) {
                return { ...cs, checked: false };
            } else {
                return cs;
            }
        });
        setFilteredFolios(newFolios);
        setSelectedFolios(newFolios.filter((f) => f.checked));
    };

    const handleEditSuccess = () => {
        setFolios([]);
        setSelectedFolios([]);
    };

    const handleSort = (value: "date" | "name") => {
        setCurrentFilter(value);
        if (value === "date") {
            if (filteredByDate) {
                switch (filteredByDate) {
                    case "desc":
                        setFilteredByDate("asc");
                        break;
                    case "asc":
                        setFilteredByDate(false);
                        break;
                }
            } else {
                setFilteredByDate("desc");
            }
        }
        if (value === "name") {
            if (filteredByName) {
                switch (filteredByName) {
                    case "desc":
                        setFilteredByName("asc");
                        break;
                    case "asc":
                        setFilteredByName(false);
                        break;
                }
            } else {
                setFilteredByName("desc");
            }
        }
    };

    const SortIcon = (props: { method: "asc" | "desc" | false; style: React.CSSProperties }) => {
        if (!props.method) {
            return <FaSort size={12} style={props.style} />;
        }
        switch (props.method) {
            case "desc":
                return <FaSortDown size={12} style={props.style} />;
            case "asc":
                return <FaSortUp size={12} style={props.style} />;
        }
    };

    useEffect(() => {
        if (folios.length !== 0) {
            if (filter !== "") {
                setFilteredFolios(folios.filter((folio) => folio.name.toLowerCase().includes(filter.toLowerCase())));
            } else {
                setFilteredFolios(folios);
            }
        }
    }, [filter, folios]);

    useEffect(() => {
        if (campaigns.length === 0) {
            // fetchCampaigns();
        }
    }, [campaigns]);

    useEffect(() => {
        const fetchFolios = async () => {
            const r = await adnFolios(globalState.get.token(), props.advertiserId);
            if (r?.code === "error.unauthorised") {
                globalState.set.token("");
            }
            if (!r.results?.length) {
                setFolios(["None"]);
            } else {
                setFolios(r.results);
            }
            setFilteredFolios(r.results);
            setFetchingFolios(false);
        };
        if (folios.length === 0 && !fetchingFolios) {
            setFetchingFolios(true);
            fetchFolios();
        }
    }, [fetchingFolios, folios, globalState.get, globalState.set, props.advertiserId]);

    useEffect(() => {
        const fetchAdvertiser = async () => {
            setFetchingAdvertiser(true);
            const r = await adnAdvertiser(globalState.get.token(), props.advertiserId);
            if (r?.code === "error.unauthorised") {
                globalState.set.token("");
            }
            setAdvertiser(r);
            setFetchingAdvertiser(false);
        };
        if (!advertiser && !fetchingAdvertiser) {
            fetchAdvertiser();
        }
    }, [advertiser, fetchingAdvertiser, globalState.get, globalState.set, props.advertiserId]);
    useEffect(() => {
        setCampaigns([]);
        setAdvertiser(undefined);
    }, [props.advertiserId]);

    useEffect(() => {
        if (folios.length !== 0) {
            if (currentFilter === "date") {
                if (!filteredByDate) {
                    const sortedFoliosArr = [...folios];
                    sortedFoliosArr.sort((a: any, b: any) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    setFilteredFolios(sortedFoliosArr);
                }
                if (filteredByDate) {
                    const sortedFoliosArr = [...folios];
                    switch (filteredByDate) {
                        case "asc":
                            sortedFoliosArr.sort(by("createTime").direction("ascending"));
                            break;
                        case "desc":
                            sortedFoliosArr.sort(by("createTime").direction("descending"));
                            break;
                    }
                    setFilteredFolios(sortedFoliosArr);
                }
            }
            if (currentFilter === "name") {
                if (filteredByName) {
                    const sortedFoliosArr = [...folios];
                    switch (filteredByName) {
                        case "asc":
                            sortedFoliosArr.sort(by("name").direction("ascending"));
                            break;
                        case "desc":
                            sortedFoliosArr.sort(by("name").direction("descending"));
                            break;
                    }
                    setFilteredFolios(sortedFoliosArr);
                }
            }
        }
    }, [currentFilter, filteredByDate, filteredByName, folios]);
    return (
        <>
            {advertiser && (
                <Create
                    type="folio"
                    visible={isCreateVisible}
                    setVisible={toggleIsCreateVisible}
                    onCreateFolio={() => setFolios([])}
                    advertiserId={advertiser.id}
                    user={props.user}
                />
            )}
            <div className="campaigns">
                <div className="top">
                    <span
                        className="back button"
                        onClick={handleBack}
                        data-tooltip-id="tt-back"
                        data-tooltip-delay-show={800}>
                        <BiArrowBack />
                    </span>
                    <Tooltip id="tt-back" content="Back to advertiser list" place="right" variant="info" noArrow />
                    <h2>
                        <BsPersonVcard />
                        <span style={{ marginTop: ".5rem", display: "inline-block" }}>
                            {advertiser ? `${advertiser?.name}` : " "}
                        </span>
                        <div
                            className="add-folio button"
                            onClick={() => {
                                setIsCreateVisible(true);
                            }}>
                            <BiFolder
                                style={{
                                    float: "left",
                                    marginRight: ".5rem",
                                    position: "relative",
                                    top: ".05rem",
                                    fontSize: 16,
                                }}
                            />
                            <span>New</span>
                        </div>
                    </h2>
                    <input
                        type="text"
                        name=""
                        id="advertiser-filter"
                        placeholder="Search folio"
                        onChange={handleFilter}
                        autoFocus={true}
                        className="folio-search"
                    />
                </div>
                <Tooltip id="tt-tags" content="Copy or download tags" place="bottom" variant="info" noArrow />
                <Tooltip id="tt-deselect" content="Deselect all creative sets" place="bottom" variant="info" noArrow />
                <Toolbox
                    type="folio"
                    items={selectedFolios}
                    onActionSuccess={handleEditSuccess}
                    isOpen={editActive}
                    onOpen={() => {
                        setEditActive(!editActive);
                    }}
                />
                <div className="list-header">
                    <span onClick={() => handleSort("name")} style={{ cursor: "pointer", marginLeft: "1.5rem" }}>
                        Folio name <SortIcon method={filteredByName} style={{ transform: "translateY(.1rem)" }} />
                    </span>
                    <span onClick={() => handleSort("date")} style={{ cursor: "pointer", marginRight: "1.5rem" }}>
                        Create date <SortIcon method={filteredByDate} style={{ transform: "translateY(.1rem)" }} />
                    </span>
                </div>
                <ul>
                    {filteredFolios.length !== 0 &&
                        filteredFolios[0] !== "None" &&
                        filteredFolios.map((folio, i) => {
                            return (
                                <li key={i} className="row space-between">
                                    <Item
                                        checkable={editActive}
                                        checked={folio.checked}
                                        handleCheck={() => handleCheck(i)}
                                        handleUnCheck={() => handleUnCheck(i)}
                                        value={
                                            <div className="row space-between">
                                                <span>
                                                    {/* <BiFolder style={{ marginRight: "1rem" }} /> */}
                                                    {folio.name}
                                                </span>
                                                <span>{folio?.createTime.split("T")[0]}</span>
                                            </div>
                                        }
                                        onClick={() => {
                                            navigate(`/folio/${folio.id}`);
                                        }}
                                    />
                                </li>
                            );
                        })}
                </ul>
            </div>
            <Tooltip
                anchorSelect=".add-folio"
                render={() => helpParser("Adds a new folio to this advertiser.")}
                place="left"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect=".folio-search"
                render={() => helpParser("Filters the list of advertisers based on your search term.")}
                place="bottom"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            {!editActive && (
                <Tooltip
                    anchorSelect=".toolbox.unopened"
                    render={() => helpParser("Enables tools for folio manipulation..")}
                    place="top"
                    variant="info"
                    opacity={1}
                    isOpen={globalState.help}
                />
            )}
        </>
    );
};
