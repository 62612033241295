import { useContext, useEffect, useState } from "react";
import { CiImageOff, CiImageOn } from "react-icons/ci";
import { getLogo } from "../functions/getLogo";
import { useGlobalState, GlobalStateManager } from "../contexts/useGlobalState";
import { BsBrightnessHigh, BsFillMoonFill } from "react-icons/bs";
import { IoHelpCircle, IoHelpCircleOutline } from "react-icons/io5";
import { Tooltip } from "react-tooltip";

export const Background = (props: { client: string; user: any }) => {
    const [background, setBackground] = useState<boolean>(true);
    const [darkmode, setDarkmode] = useState<boolean>(false);
    const [help, setHelp] = useState<boolean>(false);
    const [token, setToken] = useState<string | false>(false);
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    let logo = "./adnuntius.png";
    if (props.client) {
        logo = getLogo(props.client);
    }

    const handleBackground = () => {
        localStorage.setItem("background", `${!background}`);
        setBackground(!background);
    };
    const handleDarkmode = () => {
        localStorage.setItem("darkmode", `${!darkmode}`);
        globalState.set.darkmode(!darkmode);
        setDarkmode(!darkmode);
    };

    const handleHelp = () => {
        globalState.set.help(!globalState.help);
    };
    useEffect(() => {
        const bg = localStorage.getItem("background");
        const dm = localStorage.getItem("darkmode") === "true" ? true : false;
        if (dm !== globalState.darkmode) {
            globalState.set.darkmode(dm);
        }
        setBackground(bg === "true" ? true : false);
        setDarkmode(dm);
        setToken(globalState.get.token());
    }, [props.user, globalState.darkmode]);

    return (
        <>
            {logo && <img className={token !== "" ? "logo" : "logo login"} src={logo} alt="Studio logo"></img>}
            <div className={`background ${background ? "" : "hidden"}`}></div>
            <div className="background-btn" onClick={handleBackground}>
                {background ? <CiImageOn /> : <CiImageOff />}
            </div>
            <div className="darkmode-btn" onClick={handleDarkmode}>
                {darkmode ? <BsFillMoonFill /> : <BsBrightnessHigh />}
            </div>
            <div className="help-btn" onClick={handleHelp}>
                {globalState.help ? <IoHelpCircle /> : <IoHelpCircleOutline />}
            </div>
            <Tooltip
                anchorSelect=".darkmode-btn"
                render={() => <p>Switches between light and dark mode.</p>}
                place="left"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect=".background-btn"
                render={() => <p>Enables or disables background image.</p>}
                place="top-end"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
        </>
    );
};
