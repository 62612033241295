import { useContext, useState } from "react";
import { GlobalStateManager, useGlobalState } from "../../../contexts/useGlobalState";
import { v4 as uuidv4 } from "uuid";
import { Checkbox } from "../../Checkbox";
import { useNavigate } from "react-router-dom";
import { adnPostAdvertiser } from "../../../functions/adnuntius/advertiser";

export const CreateAdvertiser = (props: { user: any; onCreateAdvertiser: any; setVisible: any }) => {
    const [advertiserName, setAdvertiserName] = useState<string>("");
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const navigate = useNavigate();

    const handleCheck = () => {
        setIsChecked(!isChecked);
    };

    const handleChangeAdvertiserName = (e: any) => {
        setAdvertiserName(e.target.value);
    };

    const handleCreateAdvertiser = async () => {
        const advertiser = await adnPostAdvertiser(
            globalState.get.token(),
            advertiserName,
            uuidv4(),
            props.user.teams[0].id
        );
        if (advertiser.id) {
            props.setVisible();
            props.onCreateAdvertiser();
            if (isChecked) {
                navigate("/advertiser/" + advertiser.id);
            }
        }
    };
    return (
        <>
            <label htmlFor="advertiser-name">Advertiser name</label>
            <input
                type="text"
                name="advertiser-name"
                id="advertiser-name"
                onChange={handleChangeAdvertiserName}
                value={advertiserName}
                autoFocus
            />
            <div className="row end">
                <div className="button" onClick={handleCreateAdvertiser}>
                    Create
                </div>
            </div>
            <Checkbox checked={isChecked} label="Go to advertiser after creation" onCheck={handleCheck} />
        </>
    );
};
