export const Numbers = (props: { steps: number; done: number }) => {
    const nums = [];
    for (let i = 0; i < props.steps; i++) {
        nums.push(
            <span key={i} className={`check ${i < props.done ? "checked" : ""}${i === props.done ? "current" : ""}`}>
                {i + 1}
            </span>
        );
    }
    return <div className="steps">{nums.map((n) => n)}</div>;
};
