import { AdnFetchOptions, adnFetch } from "./fetch";

export const adnCreativeSet = async (token: string, creativeSetId: string) => {
    const creativeSet = await adnFetch("/v1/creativesets/" + creativeSetId, {
        token: token,
    });
    return creativeSet;
};

export interface AdnPostCreativeSetOptions {
    name: string;
    folioId: string;
    teamId?: string;
    labels?: string[];
}

export const adnPostCreativeSet = async (token: string, creativeSetId: string, opts: AdnPostCreativeSetOptions) => {
    const d = new Date().toISOString().split(".")[0] + ".000Z";
    const body = {
        id: creativeSetId,
        startDate: d,
        userState: "APPROVED",
        name: opts.name,
        folio: {
            id: opts.folioId,
        },
        labels: opts.labels ? [...opts.labels] : [],
        endDate: null,
    };
    const options: AdnFetchOptions = {
        token: token,
        options: {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        },
    };

    const creativeSet = await adnFetch("/v1/creativesets/" + creativeSetId, options);
    return creativeSet;
};

export const adnUpdateCreativeSet = async (token: string, creativeSetId: string, opts: AdnPostCreativeSetOptions) => {
    const body = {
        id: creativeSetId,
        name: opts.name,
        folio: {
            id: opts.folioId,
        },
        labels: opts.labels ? [...opts.labels] : [],
    };
    const options: AdnFetchOptions = {
        token: token,
        options: {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        },
    };
    const creativeSet = await adnFetch("/v1/creativesets/" + creativeSetId, options);
    return creativeSet;
};

export const adnDeleteCreativeSet = async (token: string, creativeSetId: string) => {
    const body = {
        id: creativeSetId,
        objectState: "INACTIVE",
    };
    const options: AdnFetchOptions = {
        token: token,
        options: {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        },
    };

    const creativeSet = await adnFetch("/v1/creativesets/" + creativeSetId, options);
    return creativeSet;
};
