import { renderToStaticMarkup } from "react-dom/server";
import { BiError } from "react-icons/bi";
import { Tabulator as TabulatorTypes } from "react-tabulator/lib/types/TabulatorTypes";

export const linkFormatter: TabulatorTypes.Formatter = (cell: any, formatterParams: any, onRendered: any) => {
    let returnString = "";
    const val = cell.getValue();
    if (cell.getField() === "clickurl") {
        if (!val) {
            returnString = renderToStaticMarkup(
                <BiError style={{ color: "#fb7188" }} size={18} data-tooltip-id="tt-link-empty" />
            );
        } else if (val && !val.includes("https://")) {
            returnString = renderToStaticMarkup(
                <>
                    <BiError style={{ color: "#e9a81d" }} size={18} data-tooltip-id="tt-link-https" />
                    {val}
                </>
            );
        } else {
            returnString = renderToStaticMarkup(<span className="link">{val}</span>);
        }
    }
    if (cell.getField() === "imptracker") {
        if (val && !val.includes("https://")) {
            returnString = renderToStaticMarkup(
                <>
                    <BiError style={{ color: "#e9a81d" }} size={18} data-tooltip-id="tt-link-https" />
                    {val}
                </>
            );
        } else {
            returnString = renderToStaticMarkup(<span className="link">{val}</span>);
        }
    }
    return returnString;
};
