import { useContext, useEffect, useState } from "react";
import { cmApi, getAccountInfo, setAccountInfo } from "../../functions/campaignManager";
import { useNavigate } from "react-router-dom";
import "../../css/campaignManager.css";
import { IoMdClose } from "react-icons/io";
import Select from "react-select";
import { tags } from "../../functions/tags";
import { useUser } from "../../contexts/useUser";
import { MdOpenInNew } from "react-icons/md";
import { TbPackageExport } from "react-icons/tb";
import { BsBuilding, BsBuildingFill } from "react-icons/bs";

type SearchOption = {
    value: string;
    label: string;
    fullObject: any;
    type: "advertiser" | "campaign";
};

type GoogleCreative = {
    name: string;
    advertiserId: string;
    accountId: string;
    active: boolean;
    archived: boolean;
    size: {
        width: number;
        height: number;
    };
    type: 3;
    htmlCode: string;
};

export const CampaignManager = (props: { creatives: any[]; creativeSetId: string }) => {
    const [processing, setProcessing] = useState<false | "creative" | "creatives" | "link" | "links" | "done">(false);
    const [profiles, setProfiles] = useState<any>([]);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);
    const [profile, setProfile] = useState<any>(null);
    const [advertiser, setAdvertiser] = useState<any>(null);
    const [advertisers, setAdvertisers] = useState<any>([]);
    const [campaigns, setCampaigns] = useState<any>([]);
    const [campaign, setCampaign] = useState<any>(null);
    const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);
    const [query, setQuery] = useState<string>("");
    const [searchType, setSearchType] = useState<"advertiser" | "campaign">("advertiser");
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const user = useContext(useUser);
    const localGoogle = localStorage.getItem("asStudio-google-oauth2-params") ?? false;
    const googleAuth = localGoogle && JSON.parse(localGoogle);
    if (googleAuth?.access_token && !isLoggedIn) {
        setIsLoggedIn(true);
    }

    const navigate = useNavigate();
    const handleLogIn = () => {
        // console.log(document.location.href);
        navigate("/GoogleAuthentication");
    };

    const handleLogOut = () => {
        localStorage.removeItem("asStudio-google-oauth2-params");
        localStorage.removeItem("asStudio-google-cm-account");
        localStorage.removeItem("state");
        setProfiles([]);
        setIsLoggedIn(false);
    };

    const handleSelectProfile = (index: number) => {
        setProfile(profiles[index]);
    };

    const getProfiles = async () => {
        try {
            const profileRequest = await cmApi.sendCmRequest("get", "/userprofiles");
            if (profileRequest?.items) {
                setProfiles(profileRequest.items);
                localStorage.removeItem("beforeAuthUrl");
            } else {
                handleLogOut();
            }
            setFetching(false);
        } catch (e) {
            // probably not signed in
            handleLogOut();
        }
    };
    const getAdvertisers = async () => {
        try {
            const profile = getAccountInfo();
            const advertiserRequest = await cmApi.sendCmRequest(
                "get",
                `/userprofiles/${profile.profileId}/advertisers`,
                {
                    searchString: query,
                }
            );
            setAdvertisers(advertiserRequest.advertisers);
            setSearchType("campaign");
        } catch (e) {
            // probably not signed in
            handleLogOut();
        }
    };

    const getCampaigns = async (id?: string) => {
        try {
            const profile = getAccountInfo();
            const campaignRequest = await cmApi.sendCmRequest("get", `/userprofiles/${profile.profileId}/campaigns`, {
                advertiserIds: [id || advertiser.id],
                maxResults: 25,
                searchString: query,
            });
            setCampaigns(campaignRequest.campaigns);
        } catch (e) {
            // probably not signed in
            handleLogOut();
        }
    };

    const handleSearchChange = (input: string) => {
        setQuery(input);
    };

    const sendCreative = async (creative: any) => {
        const tagObj = tags(creative.id, props.creativeSetId, user.teams[0].id).find(
            (tag) => tag.value === "google-cm-export"
        );
        const googleCreative: GoogleCreative = {
            name: creative.name,
            advertiserId: advertiser.id,
            accountId: advertiser.accountId,
            active: true,
            archived: false,
            size: {
                width: creative.width,
                height: creative.height,
            },
            type: 3,
            htmlCode: tagObj?.tag ?? "",
        };
        const r = await cmApi.sendCmRequest(
            "post",
            `/userprofiles/${profile.profileId}/creatives`,
            undefined,
            googleCreative
        );
        return r.id;
    };

    const linkCreative = async (creativeId: string) => {
        const linkRequest = await cmApi.sendCmRequest(
            "post",
            `/userprofiles/${profile.profileId}/campaigns/${campaign.id}/campaignCreativeAssociations`,
            undefined,
            { creativeId: creativeId }
        );
        return linkRequest.creativeId;
    };

    const handleExportCreative = async (creative: any) => {
        setProcessing("creative");
        const creativeId = await sendCreative(creative);
        setProcessing("link");
        const linkedCreativeId = await linkCreative(creativeId);
        setProcessing("done");
        setTimeout(() => {
            setProcessing(false);
        }, 1500);
    };

    const handleExportAll = async () => {
        setShowDialog(false);
        setProcessing("creatives");
        const creativeIdPromiseArr = props.creatives.map((creative) => sendCreative(creative));
        const creativeIds = await Promise.all(creativeIdPromiseArr);
        setProcessing("links");
        const linkPromiseArr = creativeIds.map((creativeId) => linkCreative(creativeId));
        const linkResults = await Promise.all(linkPromiseArr);
        setProcessing("done");
        setTimeout(() => {
            setProcessing(false);
        }, 1500);
    };

    const handleSelect = (obj: any) => {
        if (obj.type === "advertiser") {
            getCampaigns(obj.fullObject.id);
            setAdvertiser(obj.fullObject);
        }
        if (obj.type === "campaign") {
            setCampaign(obj.fullObject);
            setCampaigns([]);
            setSearchOptions([]);
        }

        setSearchOptions([]);
    };

    const handleOpenInCM = () => {
        window.open(
            `https://campaignmanager.google.com/trafficking/?pli=1#/accounts/${profile.accountId}/campaigns/${campaign.id}/explorer?statuses=0;2&systemStatuses=`
        );
    };
    useEffect(() => {
        if (isLoggedIn && profiles.length === 0) {
            if (!fetching) {
                getProfiles();
            }
        }
        if (profile) {
            setAccountInfo(profile);
        }
    }, [fetching, isLoggedIn, profile]);

    useEffect(() => {
        if (advertisers.length !== 0) {
            const opts: SearchOption[] = advertisers.map((advertiser: any) => ({
                value: advertiser.id,
                label: advertiser.name,
                fullObject: advertiser,
                type: "advertiser",
            }));
            setSearchOptions(opts);
        } else {
            setSearchOptions([]);
        }
    }, [advertisers]);

    useEffect(() => {
        if (campaigns.length !== 0) {
            const opts: SearchOption[] = campaigns.map((campaign: any) => {
                return {
                    value: campaign.id,
                    label: campaign.name,
                    fullObject: campaign,
                    type: "campaign",
                };
            });
            setSearchOptions(opts);
        } else {
            setSearchOptions([]);
        }
    }, [campaigns]);

    useEffect(() => {
        const search = async (query: string) => {
            if (searchType === "advertiser") {
                await getAdvertisers();
            }
            if (searchType === "campaign") {
                await getCampaigns();
            }
        };

        if (query !== "") {
            const debounce = setTimeout(() => {
                search(query);
            }, 1000);
            return () => {
                clearTimeout(debounce);
            };
        } else if (query === "") {
            setSearchOptions([]);
        }
    }, [query]);

    useEffect(() => {
        localStorage.setItem("beforeAuthUrl", document.location.href);
    }, []);

    return (
        <div className="campaign-manager">
            {processing && (
                <div className="processing">
                    {processing === "creative" && <p>Uploading creative</p>}
                    {processing === "creatives" && <p>Uploading all creatives</p>}
                    {processing === "link" && <p>Linking creative to campaign</p>}
                    {processing === "links" && <p>Linking all creatives to campaign</p>}
                    {processing === "done" && <p>Export done!</p>}
                </div>
            )}
            {showDialog && (
                <div className="backplate">
                    <div className="confirm">
                        <h3>Export all creatives to "{campaign.name}"?</h3>
                        <div className="buttons">
                            <div className="button yes" onClick={handleExportAll}>
                                Yes
                            </div>
                            <div className="button cancel" onClick={() => setShowDialog(false)}>
                                Cancel
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!isLoggedIn && (
                <p className="button" onClick={handleLogIn}>
                    Log in
                </p>
            )}
            {isLoggedIn && (
                <>
                    {!profile && (
                        <>
                            <p>Select profile:</p>
                            {profiles.map((profile: any, i: number) => (
                                <p className="profile-select button" key={i} onClick={() => handleSelectProfile(i)}>
                                    <BsBuildingFill /> {profile.accountName}
                                </p>
                            ))}
                        </>
                    )}
                    {profile && (
                        <>
                            <p className="crumb">
                                <strong>Profile: </strong>
                                {profile.accountName}
                                <IoMdClose
                                    onClick={() => {
                                        setProfile(null);
                                        setAdvertiser(null);
                                    }}
                                />
                            </p>
                            {advertiser && (
                                <p className="crumb">
                                    <strong>Advertiser: </strong>
                                    {advertiser.name}
                                    <IoMdClose
                                        onClick={() => {
                                            setAdvertiser(null);
                                            setCampaign(null);
                                            setSearchOptions([]);
                                        }}
                                    />
                                </p>
                            )}
                            {campaign && (
                                <>
                                    <p className="crumb">
                                        <strong>Campaign: </strong>
                                        {campaign.name}
                                        <IoMdClose
                                            onClick={() => {
                                                setCampaign(null);
                                                getCampaigns();
                                                setSearchOptions([]);
                                            }}
                                        />
                                    </p>
                                    <span className="button open" onClick={handleOpenInCM}>
                                        <MdOpenInNew /> View campaign
                                    </span>
                                </>
                            )}
                            <br />
                            {!campaign && (
                                <Select
                                    options={searchOptions}
                                    onInputChange={handleSearchChange}
                                    onChange={handleSelect}
                                    closeMenuOnSelect
                                    placeholder={!advertiser ? "Search advertiser" : "Select campaign"}
                                    controlShouldRenderValue={false}
                                    menuIsOpen={campaigns.length !== 0 || advertisers.length !== 0}
                                />
                            )}
                        </>
                    )}
                    {campaign && advertiser && profile && (
                        <div className="google-main">
                            <div className="button export-all" onClick={() => setShowDialog(true)}>
                                <TbPackageExport />
                                <span>Export all creatives</span>
                            </div>
                            <p>or export a specific creative below</p>
                            <ul>
                                {props.creatives.map((creative: any, i: number) => (
                                    <li key={i}>
                                        <span>{creative.name}</span>{" "}
                                        <div className="export button" onClick={() => handleExportCreative(creative)}>
                                            Export
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <p className="button logout" onClick={handleLogOut}>
                        Log out
                    </p>
                </>
            )}
        </div>
    );
};
