export const getEnvironment = () => {
    const href = document.location.href;
    let env: "prod" | "dev" = "prod";
    if (href.includes("dev-studio.adnunti.us") || href.includes("localhost:")) {
        env = "dev";
    } else {
        env = "prod";
    }
    if (env === "dev") {
        if (process.env.REACT_APP_ENV === "development") {
            env = "dev";
        } else if (process.env.REACT_APP_ENV === "production") {
            env = "prod";
        }
    }
    return env;
};
